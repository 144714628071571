import React from 'react';
import { Collapse } from 'antd';
import { Appointment } from '../types/modelAppointment';
import AvailableHours from './AvailableHours';
import WeekNavigation from './WeekNavigation';

/**
 * Propriétés du composant `CalendarMobile`.
 */
interface CalendarMobileProps {
  /**
   * La semaine actuelle affichée dans le calendrier, sous forme d'un tableau d'objets `Date`.
   */
  currentWeek: Date[];

  /**
   * Tableau des noms des jours correspondant aux jours de la semaine (par exemple : ["Dimanche", "Lundi", ...]).
   */
  daysOfWeek: string[];

  /**
   * La durée du service en minutes.
   */
  serviceDuration: number;

  /**
   * Liste des rendez-vous existants pour le service.
   */
  appointments: Appointment[];

  /**
   * L'heure actuellement sélectionnée (le cas échéant).
   */
  selectedHour: string | null;

  /**
   * La date actuellement sélectionnée (le cas échéant).
   */
  selectedDate: Date | null;

  /**
   * Fonction de rappel déclenchée lorsqu'un créneau horaire est cliqué.
   *
   * @param {string} hour - L'heure sélectionnée.
   * @param {Date} day - La date associée à l'heure sélectionnée.
   */
  handleHourClick: (hour: string, day: Date) => void;

  /**
   * Fonction de gestion du changement de semaine.
   *
   * @param {Date[]} newWeek - La nouvelle semaine sous forme d'un tableau d'objets `Date`.
   * @param {React.Dispatch<React.SetStateAction<Date[]>>} setCurrentWeek - Setter pour mettre à jour la semaine actuelle.
   * @param {React.Dispatch<React.SetStateAction<Date | null>>} setSelectedDate - Setter pour mettre à jour la date sélectionnée.
   */
  handleWeekChange: (
    newWeek: Date[],
    setCurrentWeek: React.Dispatch<React.SetStateAction<Date[]>>,
    setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>
  ) => void;

  /**
   * Fonction pour obtenir la semaine précédente en fonction de la semaine actuelle.
   *
   * @param {Date[]} currentWeek - La semaine actuelle sous forme d'un tableau d'objets `Date`.
   * @returns {Date[]} La semaine précédente sous forme d'un tableau d'objets `Date`.
   */
  goToPreviousWeek: (currentWeek: Date[]) => Date[];

  /**
   * Fonction pour obtenir la semaine suivante en fonction de la semaine actuelle.
   *
   * @param {Date[]} currentWeek - La semaine actuelle sous forme d'un tableau d'objets `Date`.
   * @returns {Date[]} La semaine suivante sous forme d'un tableau d'objets `Date`.
   */
  goToNextWeek: (currentWeek: Date[]) => Date[];

  /**
   * Setter pour mettre à jour la semaine actuelle.
   */
  setCurrentWeek: React.Dispatch<React.SetStateAction<Date[]>>;

  /**
   * Setter pour mettre à jour la date sélectionnée.
   */
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
}

/**
 * Composant `CalendarMobile`
 *
 * Ce composant offre une version mobile et responsive d'un calendrier hebdomadaire.
 * Il permet à l'utilisateur de naviguer entre les semaines et d'afficher les créneaux disponibles
 * pour chaque jour. Les jours sont présentés sous forme de panneaux pliables.
 *
 * @component
 * @param {CalendarMobileProps} props - Les propriétés nécessaires pour rendre le composant `CalendarMobile`.
 * @returns {JSX.Element} Un calendrier mobile affichant les créneaux disponibles.
 */
const CalendarMobile: React.FC<CalendarMobileProps> = ({
  currentWeek,
  daysOfWeek,
  serviceDuration,
  appointments,
  selectedHour,
  selectedDate,
  handleHourClick,
  handleWeekChange,
  goToPreviousWeek,
  goToNextWeek,
  setCurrentWeek,
  setSelectedDate,
}) => (
  <Collapse>
    <WeekNavigation
      goToPreviousWeek={() =>
        handleWeekChange(goToPreviousWeek(currentWeek), setCurrentWeek, setSelectedDate)
      }
      goToNextWeek={() =>
        handleWeekChange(goToNextWeek(currentWeek), setCurrentWeek, setSelectedDate)
      }
      currentWeek={currentWeek}
    />
    {currentWeek.map((date) => (
      <Collapse.Panel
        header={date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long' })}
        key={date.toISOString()}
      >
        <AvailableHours
          date={date}
          daysOfWeek={daysOfWeek}
          serviceDuration={serviceDuration}
          appointments={appointments}
          selectedHour={selectedHour}
          selectedDate={selectedDate}
          handleHourClick={(hour: string, day: Date) => handleHourClick(hour, day)}
          dayIndex={currentWeek.indexOf(date)}
          gridColumns={1}
        />
      </Collapse.Panel>
    ))}
  </Collapse>
);

export default CalendarMobile;
