import dayjs from "dayjs";
import cartApi from "../../Cart/services/cart.api";
import { CloseAnyModalAction } from "../../common/state/modal.slice";
import { Appointment } from "../types/modelAppointment";
import { Dispatch } from "react";

/**
 * Formate une date en chaîne UTC pour l'affichage.
 *
 * @param {Date} date - La date à formater.
 * @returns {string} Une chaîne formatée en locale "fr-FR" ou "Date invalide" si la date n'est pas valide.
 */
export const formattedUTCDate = (date: Date): string => {
  if (date instanceof Date) {
    return date.toLocaleDateString("fr-FR", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "UTC",
    });
  } else {
    return "Date invalide";
  }
};

/**
 * Formate une durée en minutes en une chaîne lisible.
 *
 * @param {number} duration - La durée en minutes.
 * @returns {string} Une chaîne formatée comme "1h30" ou "90 min".
 */
export const formatDuration = (duration: number): string => {
  if (duration >= 60) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${hours}h${minutes === 0 ? "" : minutes}`;
  } else {
    return `${duration} min`;
  }
};

/**
 * Formate une date pour l'affichage en utilisant Day.js.
 *
 * @param {Date} date - La date à formater.
 * @returns {string} Une chaîne formatée comme "Lundi 20 Novembre 2024 à 14:30".
 */
export const formatDate = (date: Date): string => {
  return (
    dayjs(date).format("dddd D MMMM YYYY") +
    " à " +
    dayjs(date).format("HH:mm")
  ).replace(/^(.)/, (match) => match.toUpperCase());
};

/**
 * Liste des jours de la semaine en français.
 */
export const daysOfWeek = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];

/**
 * Récupère les jours d'une semaine à partir d'une date donnée.
 *
 * @param {Date} startDate - La date de début.
 * @returns {Date[]} Un tableau de dates représentant la semaine.
 */
export const getWeekDays = (startDate: Date): Date[] => {
  const startOfWeek = new Date(
    startDate.setDate(startDate.getDate() - startDate.getDay())
  );
  return Array.from({ length: 7 }, (_, i) => {
    const date = new Date(startOfWeek);
    date.setDate(date.getDate() + i);
    return date;
  });
};

/**
 * Passe à la semaine précédente.
 *
 * @param {Date[]} currentWeek - La semaine actuelle sous forme de tableau de dates.
 * @returns {Date[]} La semaine précédente sous forme de tableau de dates.
 */
export const goToPreviousWeek = (currentWeek: Date[]): Date[] => {
  const newStartDate = new Date(currentWeek[0]);
  newStartDate.setDate(newStartDate.getDate() - 7);
  return getWeekDays(newStartDate);
};

/**
 * Passe à la semaine suivante.
 *
 * @param {Date[]} currentWeek - La semaine actuelle sous forme de tableau de dates.
 * @returns {Date[]} La semaine suivante sous forme de tableau de dates.
 */
export const goToNextWeek = (currentWeek: Date[]): Date[] => {
  const newStartDate = new Date(currentWeek[6]);
  newStartDate.setDate(newStartDate.getDate() + 1);
  return getWeekDays(newStartDate);
};

const openingHours: Record<string, { startHour: number; endHour: number; interval: number }> = {
  Lundi: { startHour: 10, endHour: 18, interval: 15 },
  Mardi: null!,
  Mercredi: { startHour: 10, endHour: 18, interval: 15 },
  Jeudi: { startHour: 10, endHour: 13, interval: 15 },
  Vendredi: { startHour: 10, endHour: 18, interval: 15 },
  Samedi: { startHour: 10, endHour: 15, interval: 15 },
  Dimanche: null!, // Fermé
};

const specialDays: Record<string, { startHour: number; endHour: number; interval: number }> = {
  // je veux que ce soit fermé le 25 décembre et le 1er janvier
  "2024-12-25": null!,
  "2025-01-01": null!,
};


/**
 * Récupère les créneaux disponibles pour un jour donné en fonction de la configuration.
 *
 * @param {string} dayOfWeek - Le jour de la semaine (exemple : "Lundi").
 * @param {number} serviceDuration - La durée du service en minutes.
 * @param {Date} date - La date pour laquelle calculer les créneaux disponibles.
 * @returns {string[]} Un tableau des créneaux horaires disponibles.
 */
export const getAvailableHours = (dayOfWeek: string, serviceDuration: number, date: Date): string[] => {
  const formattedDate = dayjs(date).format("YYYY-MM-DD");

  // Gestion des jours spéciaux
  if (formattedDate in specialDays && specialDays[formattedDate] === null) {
    console.log(`${formattedDate} est fermé (Jour spécial).`);
    return [];
  }

  const config = specialDays[formattedDate] ?? openingHours[dayOfWeek];

  if (!config) {
    console.log(`Jour fermé: ${formattedDate}`);
    return [];
  }

  const { startHour, endHour, interval } = config;
  const hours: string[] = [];

  for (let hour = startHour; hour <= endHour; hour++) {
    for (let minute = 0; minute < 60; minute += interval) {
      if (hour === endHour && minute + serviceDuration > 60) break;
      hours.push(`${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`);
    }
  }

  return hours;
};

/**
 * Vérifie si un créneau horaire est disponible pour un jour donné.
 *
 * @param {string} hour - Le créneau horaire (exemple : "14:30").
 * @param {Date} day - La date associée au créneau.
 * @param {string[]} daysOfWeek - Tableau des noms des jours.
 * @param {number} serviceDuration - La durée du service en minutes.
 * @param {Appointment[]} appointments - Les rendez-vous existants.
 * @param {Date[]} cartDates - Les dates actuellement dans le panier.
 * @returns {boolean} Vrai si le créneau est disponible, sinon faux.
 */
export const isHourAvailable = (
  hour: string,
  day: Date,
  daysOfWeek: string[],
  serviceDuration: number,
  appointments: Appointment[],
  cartDates: Date[]
): boolean => {
  const dayOfWeek = daysOfWeek[day.getDay()];
  const availableHours = getAvailableHours(dayOfWeek, serviceDuration, day);

  if (!availableHours.includes(hour)) {
    return false;
  }

  const [hourNum, minuteNum] = hour.split(":").map(Number);

  // Vérifie si un rendez-vous existe déjà sur ce créneau
  const appointmentExists = appointments.some((appointment) => {
    const appointmentDate = new Date(appointment.date);
    return (
      appointmentDate.getFullYear() === day.getFullYear() &&
      appointmentDate.getMonth() === day.getMonth() &&
      appointmentDate.getDate() === day.getDate() &&
      appointmentDate.getHours() === hourNum &&
      appointmentDate.getMinutes() === minuteNum
    );
  });

  if (appointmentExists) {
    return false;
  }

  const now = new Date();
  const slotTime = new Date(day);
  slotTime.setHours(hourNum, minuteNum, 0, 0);

  // Vérifie si le créneau est dans le passé ou trop proche
  if (slotTime < now || slotTime < new Date(now.setHours(now.getHours() + 2))) {
    return false;
  }

  // Vérifie si le créneau est déjà dans le panier
  return !cartDates.some((cartDate) => {
    return (
      cartDate.getFullYear() === day.getFullYear() &&
      cartDate.getMonth() === day.getMonth() &&
      cartDate.getDate() === day.getDate() &&
      cartDate.getHours() === hourNum &&
      cartDate.getMinutes() === minuteNum
    );
  });
};

/**
 * Gère le clic sur un créneau horaire.
 *
 * @param {string} hour - L'heure sélectionnée.
 * @param {Date} day - La date sélectionnée.
 * @param {Dispatch<React.SetStateAction<Date | null>>} setSelectedDate - Setter pour mettre à jour la date sélectionnée.
 * @param {Dispatch<React.SetStateAction<string | null>>} setSelectedHour - Setter pour mettre à jour l'heure sélectionnée.
 * @param {(date: Date) => void} onDateSelect - Callback pour la sélection d'une date.
 * @param {(date: Date) => void} onDateTimeSelect - Callback pour la sélection d'une date et d'une heure.
 * @param {Dispatch<any>} dispatch - Fonction dispatch Redux.
 * @param {string} cartId - L'identifiant du panier.
 */
export const handleHourClick = (
  hour: string,
  day: Date,
  setSelectedDate: Dispatch<React.SetStateAction<Date | null>>,
  setSelectedHour: Dispatch<React.SetStateAction<string | null>>,
  onDateSelect: (date: Date) => void,
  onDateTimeSelect: (date: Date) => void,
  dispatch: Dispatch<any>,
  cartId: string
) => {
  const newSelectedDateTime = new Date(day);
  const [hourNum, minutes] = hour.split(":").map(Number);
  newSelectedDateTime.setHours(hourNum, minutes, 0, 0);

  setSelectedDate(newSelectedDateTime);
  setSelectedHour(hour);
  onDateSelect(newSelectedDateTime);
  onDateTimeSelect(newSelectedDateTime);
  cartApi.updateCartDate(cartId, newSelectedDateTime);
  dispatch(CloseAnyModalAction());
};

/**
 * Gère le changement de semaine.
 *
 * @param {Date[]} newWeek - La nouvelle semaine sous forme de tableau de dates.
 * @param {Dispatch<React.SetStateAction<Date[]>>} setCurrentWeek - Setter pour mettre à jour la semaine actuelle.
 * @param {Dispatch<React.SetStateAction<Date | null>>} setSelectedDate - Setter pour réinitialiser la date sélectionnée.
 */
export const handleWeekChange = (
  newWeek: Date[],
  setCurrentWeek: Dispatch<React.SetStateAction<Date[]>>,
  setSelectedDate: Dispatch<React.SetStateAction<Date | null>>
) => {
  setCurrentWeek(newWeek);
  setSelectedDate(null);
};
