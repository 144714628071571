import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RootState } from './';
import { persistor } from './configureStore';
import { refreshTokenAction, selectIsLoggedIn } from '../../Auth/state/auth.slice';

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = {
  // isRegistrating: isRegistratingAction,
  refreshToken: refreshTokenAction,
  // getMe: getMeActions.request,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    children: ReactNode;
    loading: ReactNode;
  };

  const StorePersistGate = ({ children, loading, refreshToken, isLoggedIn }: Props) => (
    <PersistGate
      loading={loading}
      onBeforeLift={() => {
        console.log("isLoggedIn:", isLoggedIn);
        if (isLoggedIn) {
          console.log("Utilisateur connecté, rafraîchissement du token...");
          refreshToken();
        } else {
          console.log("Aucun utilisateur connecté, aucune action.");
        }
      }}
      persistor={persistor}
    >
      {children}
    </PersistGate>
  );
  
  

export default connect(mapStateToProps, mapDispatchToProps)(StorePersistGate);
