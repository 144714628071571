import apiClient from "../../common/services/apiClient";
import { trackProgress } from "../../common/services/trackProgress";

const CONTACT_PATH_API = "contact";

/**
 * Classe `ContactApi`
 *
 * Cette classe gère les appels à l'API pour les opérations liées aux messages de contact,
 * comme l'envoi de messages depuis le formulaire de contact.
 */
class ContactApi {
  /**
   * Envoie un message de contact.
   *
   * @param {string} name - Le nom de l'utilisateur.
   * @param {string} email - L'email de l'utilisateur.
   * @param {string} message - Le message de l'utilisateur.
   * @returns {Promise<void>} Une promesse indiquant le succès ou l'échec de l'envoi du message.
   */
  sendMessage(name: string, email: string, message: string) {
    return trackProgress(
      apiClient.post(`${CONTACT_PATH_API}/send`, { name, email, message }),
      `POST_${CONTACT_PATH_API}_send`
    );
  }
}

const contactApi = new ContactApi();
export default contactApi;
