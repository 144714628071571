import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../../common/hooks";
import { ROUTES } from "../../../common/router";
import { UserRolesEnum } from "../../../User/types/UserTypes";
import { selectIsLoggedIn, selectUser } from "../../state/auth.slice";
import LoginForm from "./LoginForm";

/**
 * Composant `LoginPage`
 *
 * Cette page gère la connexion des utilisateurs et redirige automatiquement en fonction du rôle de l'utilisateur (client, administrateur, esthéticienne).
 * Si l'utilisateur n'est pas connecté, un formulaire de connexion est affiché.
 *
 * @composant
 * @returns {JSX.Element} Une page de connexion ou une redirection en fonction du rôle de l'utilisateur connecté.
 */
const LoginPage = () => {
  const { t } = useTranslation();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const user = useAppSelector(selectUser);

  if (isLoggedIn && user?.role === UserRolesEnum.client) {
    return (
      <>
        <Navigate to={ROUTES.CLIENT.DASHBOARD} />
      </>
    );
  } else if (isLoggedIn && user?.role === UserRolesEnum.admin) {
    return (
      <>
        <Navigate to={ROUTES.ADMIN.DASHBOARD} />
      </>
    );
  } else if (isLoggedIn && user?.role === UserRolesEnum.esthetician) {
    return (
      <>
        <Navigate to={ROUTES.ESTH.DASHBOARD} />
      </>
    );
  }

  return (
    <>
      <h1 className="titleForm">{t("UI.LOGIN_PAGE.title")}</h1>
      <LoginForm />
    </>
  );
};

export default LoginPage;
