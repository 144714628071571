import React from "react";

/**
 * Propriétés du composant `WeekHeader`.
 */
type WeekHeaderProps = {
  /**
   * Tableau de dates représentant la semaine actuelle.
   */
  currentWeek: Date[];

  /**
   * Tableau des noms des jours correspondant aux jours de la semaine (par exemple : ["Lundi", "Mardi", ...]).
   */
  daysOfWeek: string[];
};

/**
 * Composant `WeekHeader`
 *
 * Ce composant affiche un en-tête pour la semaine actuelle, comprenant les noms des jours
 * et leurs dates correspondantes. Il utilise une disposition en grille responsive
 * et reste fixe en haut du conteneur (`sticky`) pour garantir sa visibilité lors du défilement.
 *
 * @composant
 * @param {WeekHeaderProps} props - Les propriétés nécessaires pour rendre le composant `WeekHeader`.
 * @returns {JSX.Element} Une grille responsive affichant les jours de la semaine et leurs dates.
 */
const WeekHeader: React.FC<WeekHeaderProps> = ({ currentWeek, daysOfWeek }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-8 p-4 sticky top-0 bg-white z-10">
      {daysOfWeek.map((day, index) => (
        <div key={index} className="flex flex-col text-center">
          <span className="lowercase text-base">{day}</span>
          <span className="text-base">
            {currentWeek[index].getDate()}{" "}
            {currentWeek[index].toLocaleDateString("fr-FR", {
              month: "short",
            })}
          </span>
        </div>
      ))}
    </div>
  );
};

export default WeekHeader;
