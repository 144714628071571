import {
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { Service } from "../../Services/services/types/modelService";

/**
 * Énumération des clés pour les actions du menu des services.
 */
export enum MenuItemContactKeysEnum {
  /** Clé pour l'action de modification d'un service */
  editService = "editService",
  /** Clé pour l'action de suppression d'un service */
  deleteService = "deleteService",
}

/**
 * Génère les éléments de menu pour un service donné.
 *
 * @param {Service} service - Le service pour lequel les éléments du menu sont générés.
 * @param {(service: Service, infos: any) => void} onItemClick - Fonction de callback pour gérer les clics sur les éléments du menu.
 * @returns {MenuProps["items"]} - La liste des éléments du menu.
 */
export const getServiceMenuItems = (
  service: Service,
  onItemClick: (service: Service, infos: any) => void
): MenuProps["items"] => [
  {
    key: '1',
    type: 'group', // Regroupe les actions sous un label commun
    label: 'Actions',
    children: [
      {
        // Élement pour l'action de modification
        label: "Modifier", // Texte affiché dans le menu
        key: MenuItemContactKeysEnum.editService, // Clé unique pour identifier cette action
        icon: <EditOutlined />, // Icône associée à l'action
        onClick: (infos: any) => onItemClick(service, infos), // Appelle la fonction de gestion des clics avec les informations correspondantes
      },
      {
        // Élement pour l'action de suppression
        label: "Supprimer",
        key: MenuItemContactKeysEnum.deleteService,
        icon: <DeleteOutlined />,
        onClick: (infos: any) => onItemClick(service, infos),
      },
    ],
  },
];