import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../../common/store";
import authService from "../services/auth.api";
import { AppAuth } from "../types";
import {
  loginAction,
  loginErrorAction,
  loginSuccessAction,
  refreshTokenAction,
  registerAction,
  registerErrorAction,
  registerSuccessAction,
} from "./auth.slice";

/**
 * Génère un message d'erreur basé sur l'erreur reçue.
 * @param {any} err - L'erreur reçue.
 * @param {string} defaultMessage - Le message par défaut.
 * @returns {string} - Le message d'erreur à afficher.
 */
const getErrorMessage = (err: any, defaultMessage: string): string => {
  if (err.response) {
    switch (err.response.status) {
      case 401:
        return "Le mot de passe est incorrect.";
      case 400:
        return "Requête invalide. Veuillez vérifier vos informations.";
      default:
        return err.response.data?.message || defaultMessage;
    }
  }
  return defaultMessage;
};

/**
 * Gère la connexion utilisateur.
 */
function* login({ payload: { email, password } }: ReturnType<typeof loginAction>) {
  try {
    const resp: AppAuth = yield call(authService.login, email, password);
    yield put(loginSuccessAction(resp));
  } catch (err: any) {
    const errorMessage = getErrorMessage(err, "Une erreur s'est produite lors de la connexion.");
    yield put(loginErrorAction(errorMessage));
  }
}

/**
 * Gère le rafraîchissement du token d'authentification.
 */
function* refresh({ payload }: ReturnType<typeof refreshTokenAction>) {
  try {
    const {
      authService: { auth },
    }: RootState = yield select();

    const token = auth?.token;
    if (token) {
      const resp: AppAuth = yield call(authService.refreshToken, token);
      yield put(loginSuccessAction(resp));
    }
  } catch (err: any) {
    const errorMessage = getErrorMessage(err, "Une erreur s'est produite lors du rafraîchissement du token.");
    yield put(loginErrorAction(errorMessage));
  }
}

/**
 * Gère l'inscription d'un nouvel utilisateur.
 */
function* register({
  payload: { firstName, lastName, mobile, email, password },
}: ReturnType<typeof registerAction>) {
  try {
    const resp: AppAuth = yield call(
      authService.register,
      firstName,
      lastName,
      mobile,
      email,
      password
    );
    yield put(registerSuccessAction(resp));
    yield put(refreshTokenAction());
  } catch (err: any) {
    const errorMessage = getErrorMessage(err, "Une erreur s'est produite lors de l'inscription.");
    yield put(registerErrorAction(errorMessage));
  }
}

/**
 * Auth Saga
 */
export function* authSaga() {
  yield takeLatest(loginAction, login);
  yield takeLatest(registerAction, register);
  yield takeLatest(refreshTokenAction, refresh);
}
