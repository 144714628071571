import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { formatDuration } from "../Appointment/Calendar/dateUtils";
import { useCart } from "../common/contexts/CartContext";
import { Service } from "./services/types/modelService";
import { Badge } from "antd";
import tailwindTheme from "../tailwind-theme";

type ServiceCardProps = {
  service: Service;
  addToCart: (service: Service, date: Date) => void;
};

const CardService = ({ service, addToCart }: ServiceCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { cart } = useCart();

  // Vérifier si le service est déjà dans le panier
  const isInCart = cart?.rows?.some(
    (cartItem) => cartItem.serviceId?._id === service._id
  );

  return (
    <div
      key={service.id}
      className="w-44 h-44 rounded-lg shadow transition-all duration-500 ease-in-out transform hover:shadow-md relative"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.3) 60%, #c79d79 100%, #c79d79 80%), url(${service.backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        filter: "brightness(90%)",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Nom du service */}
      <div className="absolute top-10 text-center font-yusei left-0 right-0 p-4">
        <h3
          className="text-white text-base uppercase tracking-wide cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {service.name}
        </h3>
      </div>

      {/* Coche visible uniquement en vue mobile */}
      {isInCart && (
        <div className="absolute top-2 right-2 bg-green-500 rounded-full p-2 text-white block sm:hidden">
          <Badge
            style={{
              backgroundColor: tailwindTheme.colors.jb.primary,
              color: "white",
            }}
          count={1} />
        </div>
      )}

      {/* Prix et durée en hover */}
      {isHovered && (
        <div className="price-animation absolute z-10 px-10 rounded-t-lg rounded-br-lg rounded-tr-none bg-opacity-50 text-lg bottom-0 font-yusei right-0 text-white bg-jb-primary">
          {service.price}€
        </div>
      )}
      {isHovered && (
        <div className="price-animation absolute z-10 px-10 rounded-t-lg rounded-br-lg rounded-tr-none bg-opacity-50 text-base top-0 font-yusei left-0 text-white bg-jb-primary">
          <p>{formatDuration(service.duration)}</p>
        </div>
      )}

      {/* Bouton Ajouter au panier */}
      {isHovered && (
        <div className="hover-icon-animation rounded-lg absolute w-full h-full flex items-center justify-center bg-black bg-opacity-30">
          <PlusOutlined
            onClick={(e) => {
              e.stopPropagation();
              addToCart(service, new Date(undefined!));
            }}
            style={{ fontSize: "3em", color: "white" }}
          />
        </div>
      )}
    </div>
  );
};

export default CardService;
