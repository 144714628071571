import PageCanvas from "../../common/components/Panels/PageCanvas";
import AdminListUser from "./AdminListUser";

/**
 * Page d'affichage de la liste des utilisateurs administrateurs.
 *
 * Ce composant encapsule la liste des comptes administrateurs dans un canevas (PageCanvas)
 * pour structurer la présentation et fournir un titre à la page.
 *
 * @returns {JSX.Element} - Composant React représentant la page.
 */
const AdminListUserPage = (): JSX.Element => {
  return (
    <>
      {/* Canevas de la page avec un titre */}
      <PageCanvas title="Liste des comptes">
        {/* Contenu principal de la page */}
        <PageCanvas.Content>
          <AdminListUser />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default AdminListUserPage;
