import { IdType, TokenType } from "../../common/store/types";
import { UserRolesEnum } from "../../User/types/UserTypes";

/**
 * Constante représentant le nom du service d'authentification.
 */
export const AUTH_SERVICE = "authService";

/**
 * Type TypeScript associé à la constante `AUTH_SERVICE`.
 */
// eslint-disable-next-line
export type AUTH_SERVICE = typeof AUTH_SERVICE;

/* ======== TYPES ======== */

/**
 * Type représentant un utilisateur authentifié.
 *
 * @template K - Les propriétés additionnelles associées à l'utilisateur.
 */
export type AuthUserType<K> = K & {
  /**
   * Identifiant unique de l'utilisateur.
   */
  id: IdType;

  /**
   * Adresse email de l'utilisateur.
   */
  email: string;

  /**
   * Prénom de l'utilisateur.
   */
  firstname: string;

  /**
   * Nom de famille de l'utilisateur.
   */
  lastName: string;

  /**
   * Numéro de téléphone de l'utilisateur.
   */
  mobile: string;

  /**
   * Rôle de l'utilisateur, défini dans `UserRolesEnum`.
   */
  role: UserRolesEnum;
};

/**
 * Type représentant l'état d'authentification.
 *
 * @template K - Les propriétés additionnelles associées à l'utilisateur.
 */
export type AuthStateType<K> = {
  /**
   * Token d'authentification de l'utilisateur.
   */
  token: TokenType;

  /**
   * Données de l'utilisateur authentifié.
   */
  user: AuthUserType<K>;
};

/* ======== PAYLOADS ======== */

/**
 * Charge utile pour l'action de connexion.
 */
export type LoginPayload = {
  /**
   * Adresse email utilisée pour la connexion.
   */
  email: string;

  /**
   * Mot de passe associé à l'utilisateur.
   */
  password: string;
};

/**
 * Charge utile pour l'action d'inscription.
 */
export type RegisterPayload = {
  /**
   * Prénom de l'utilisateur à enregistrer.
   */
  firstName: string;

  /**
   * Nom de famille de l'utilisateur à enregistrer.
   */
  lastName: string;

  /**
   * Adresse email utilisée pour l'inscription.
   */
  email: string;

  /**
   * Numéro de téléphone de l'utilisateur à enregistrer.
   */
  mobile: string;

  /**
   * Mot de passe choisi par l'utilisateur.
   */
  password: string;
};
