import React from "react";
import { Collapse, Card, Button, Empty } from "antd";
import { Service } from "../../Services/services/types/modelService";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import { openModalAction } from "../../common/state/modal.slice";
import { useAppDispatch } from "../../common/hooks";
import ServiceForm from "./Forms/ServiceForm";

/**
 * Propriétés du composant `AdminListServicesMobile`.
 */
interface AdminListServicesMobileProps {
  /** Liste des services disponibles */
  services: Service[] | undefined;
  /** Fonction pour récupérer les services après modification ou suppression */
  fetchServiceAdmin: () => void;
}

/**
 * Composant mobile pour afficher et gérer les services dans une vue en accordéon.
 *
 * @param {AdminListServicesMobileProps} props - Les propriétés du composant.
 * @returns {JSX.Element} - Le rendu du composant.
 */
const AdminListServicesMobile: React.FC<AdminListServicesMobileProps> = ({
  services,
  fetchServiceAdmin,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  /**
   * Ouvre un modal pour modifier un service.
   *
   * @param {Service} service - Le service à modifier.
   */
  const openModalEditService = (service: Service): void => {
    dispatch(
      openModalAction({
        component: (
          <ServiceForm
            service={service}
            formCb={() => fetchServiceAdmin()} // Rafraîchit la liste des services après modification
          />
        ),
        options: { title: "Modifier un service" },
      })
    );
  };

  /**
   * Ouvre un modal pour confirmer la suppression d'un service.
   *
   * @param {Service} service - Le service à supprimer.
   */
  const openModalDeleteService = (service: Service): void => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm) {
                // Appeler l'API de suppression réelle ici
                console.log(`Service supprimé avec l'ID : ${service._id}`);
                fetchServiceAdmin(); // Rafraîchit la liste des services après suppression
              }
            }}
            confirmIsDanger
            body="Êtes-vous sûr de vouloir supprimer ce service ?"
          />
        ),
        options: { title: "Supprimer un service" },
      })
    );
  };

  return (
    <div>
      {/* Si des services sont disponibles, affiche un accordéon, sinon affiche un message vide */}
      {services && services.length > 0 ? (
        <Collapse>
          {services.map((service) => (
            <Collapse.Panel
              key={service._id!} // Identifiant unique pour chaque panneau
              header={service.name} // Titre du panneau
              extra={
                // Image miniature dans l'en-tête du panneau
                <img
                  src={service.backgroundImage}
                  alt={service.name}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
              }
            >
              <Card
                title={service.name} // Titre de la carte
                cover={
                  service.backgroundImage && (
                    // Image principale si elle est disponible
                    <img
                      src={service.backgroundImage}
                      alt={service.name}
                      style={{
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  )
                }
                actions={[
                  // Boutons d'action pour modifier et supprimer
                  <Button key="edit" onClick={() => openModalEditService(service)}>
                    Modifier
                  </Button>,
                  <Button
                    key="delete"
                    danger
                    onClick={() => openModalDeleteService(service)}
                  >
                    Supprimer
                  </Button>,
                ]}
              >
                {/* Informations supplémentaires sur le service */}
                <p>
                  <strong>Nom :</strong> {service.name}
                </p>
              </Card>
            </Collapse.Panel>
          ))}
        </Collapse>
      ) : (
        // Message lorsqu'aucun service n'est disponible
        <Empty description="Aucun service trouvé" />
      )}
    </div>
  );
};

export default AdminListServicesMobile;
