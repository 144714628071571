import { AppointmentStatusEnum, IAppointment } from "../../Appointment/types/appointmentTypes";
import { Appointment } from "../../Appointment/types/modelAppointment";
import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { ApiListResult } from "../../common/services/types";
import { Service } from "../../Services/services/types/modelService";
import { IService } from '../../Services/services/types/serviceTypes';
import { User } from "../../User/types/modelUser";
import { IUser } from '../../User/types/UserTypes';

const _PATH_API = "admin/";

/**
 * Classe représentant les appels API pour les fonctionnalités administratives.
 * Étend la classe générique `ApiService` pour fournir des opérations CRUD spécifiques.
 */
class AdminApi extends ApiService<IAppointment> {
  /**
   * Constructeur pour initialiser le chemin d'accès de base.
   */
  constructor() {
    super(_PATH_API);
  }

  /**
   * Récupère une liste de rendez-vous pour les administrateurs.
   * @param query - Les critères de recherche pour les rendez-vous.
   * @param page - La page actuelle pour la pagination.
   * @param pageSize - Le nombre d'éléments par page.
   * @returns Une promesse contenant la liste des rendez-vous et le compte total.
   */
  findAppointmentsAdmin = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Appointment[] }> => {
    return this.custom<ApiListResult<Appointment>>("get", `appointments`, {
      query,
      page,
      pageSize,
    }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Appointment(r)),
      };
    });
  };

  /**
   * Crée un nouveau rendez-vous.
   * @param data - Les données pour le rendez-vous.
   * @returns Une promesse contenant le rendez-vous créé.
   */
  createAppointment = (data: Partial<IAppointment>) => { 
    return this.custom<Appointment>("post", `appointments`, {}, data, {
      "Content-Type": "application/json",
    });
  };

  /**
   * Met à jour un rendez-vous existant.
   * @param appointmentId - L'identifiant du rendez-vous.
   * @param data - Les données mises à jour pour le rendez-vous.
   * @returns Une promesse contenant le rendez-vous mis à jour.
   */
  updateAppointment = (appointmentId: string, data: Partial<IAppointment>) => {
    return this.custom<Appointment>("put", `appointments/update/${appointmentId}`, {}, data);
  };

  /**
   * Met à jour le statut d'un rendez-vous.
   * @param appointmentId - L'identifiant du rendez-vous.
   * @param status - Le nouveau statut du rendez-vous.
   * @returns Une promesse contenant le rendez-vous avec le statut mis à jour.
   */
  updateAppointmentStatus = async (
    appointmentId: string,
    status: AppointmentStatusEnum
  ) => {
    return this.custom<Appointment>("put", `appointments/updateStatus/${appointmentId}`, {}, { status });
  };

  /**
   * Supprime un rendez-vous.
   * @param appointmentId - L'identifiant du rendez-vous.
   * @returns Une promesse résolue lorsque le rendez-vous est supprimé.
   */
  deleteAppointment = (appointmentId: string) => {
    return this.custom<Appointment>("delete", `appointments/${appointmentId}`);
  };

  /**
   * Récupère une liste d'utilisateurs pour les administrateurs.
   * @param query - Les critères de recherche pour les utilisateurs.
   * @param page - La page actuelle pour la pagination.
   * @param pageSize - Le nombre d'éléments par page.
   * @returns Une promesse contenant la liste des utilisateurs et le compte total.
   */
  findUsersAdmin = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: User[] }> => {
    return this.custom<ApiListResult<User>>("get", `accounts`, {
      query,
      page,
      pageSize,
    }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new User(r)),
      };
    });
  };

  /**
   * Récupère une liste de services pour les administrateurs.
   * @param query - Les critères de recherche pour les services.
   * @param page - La page actuelle pour la pagination.
   * @param pageSize - Le nombre d'éléments par page.
   * @returns Une promesse contenant la liste des services et le compte total.
   */
  findServicesAdmin = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Service[] }> => {
    return this.custom<ApiListResult<Service>>("get", `services`, {
      query,
      page,
      pageSize,
    }).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Service(r)),
      };
    });
  };

  /**
   * Crée un nouveau service.
   * @param data - Les données pour le service.
   * @returns Une promesse contenant le service créé.
   */
  createServicesAdmin = (data: IService) => {
    return this.custom<Service>("post", `services`, {}, data, {
      "Content-Type": "application/json",
    });
  };

  /**
   * Met à jour un service existant.
   * @param serviceId - L'identifiant du service.
   * @param data - Les données mises à jour pour le service.
   * @returns Une promesse contenant le service mis à jour.
   */
  updateServicesAdmin = (serviceId: string, data: IService) => {
    return this.custom<Service>("put", `services/${serviceId}`, {}, data);
  };

  /**
   * Télécharge une image pour un service.
   * @param data - Les données du formulaire contenant l'image.
   * @returns Une promesse contenant le service mis à jour avec l'image.
   */
  uploadImageService = (data: FormData) => {
    return this.custom<Service>("post", `services/upload-image`, {}, data);
  };

  /**
   * Supprime un service.
   * @param serviceId - L'identifiant du service.
   * @returns Une promesse résolue lorsque le service est supprimé.
   */
  deleteService = (serviceId: string) => {
    return this.custom<Service>("delete", `services/${serviceId}`);
  };

  /**
   * Met à jour un utilisateur existant.
   * @param userId - L'identifiant de l'utilisateur.
   * @param data - Les données mises à jour pour l'utilisateur.
   * @returns Une promesse contenant l'utilisateur mis à jour.
   */
  updateUser = (userId: string, data: Partial<IUser>) => {
    return this.custom<User>("put", `accounts/${userId}`, {}, data);
  };

  /**
   * Supprime un utilisateur.
   * @param userId - L'identifiant de l'utilisateur.
   * @returns Une promesse résolue lorsque l'utilisateur est supprimé.
   */
  deleteUser = (userId: string) => {
    return this.custom<User>("delete", `accounts/${userId}`);
  };
}

// Instance unique pour accéder aux fonctions Admin API.
const adminApi = new AdminApi();

export default adminApi;
