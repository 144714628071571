import { Button } from "antd";
import PageCanvas from "../../common/components/Panels/PageCanvas";
import AdminListServices from "./AdminListServices";
import { closeModalAction, openModalAction } from "../../common/state/modal.slice";
import { useAppDispatch } from "../../common/hooks";
import ServiceForm from "./Forms/ServiceForm";
import { useRefresh } from "../../common/contexts/RefreshContext";

/**
 * Page d'administration pour gérer les prestations.
 *
 * Ce composant affiche une liste des prestations existantes et permet d'ajouter une nouvelle prestation
 * via un formulaire dans un modal.
 *
 * @returns {JSX.Element} - Le composant de la page d'administration des prestations.
 */
const AdminListServicesPage = () => {
  const dispatch = useAppDispatch(); // Récupération de la fonction `dispatch` pour gérer les actions Redux.
  const { triggerRefresh } = useRefresh(); // Récupération de la fonction de rafraîchissement du contexte.

  /**
   * Ouvre un modal contenant le formulaire pour ajouter une nouvelle prestation.
   * Une fois la prestation ajoutée, le modal est automatiquement fermé et les données sont rafraîchies.
   */
  const openModalNewService = () => {
    dispatch(
      openModalAction({
        // Composant du formulaire pour ajouter une prestation
        component: (
          <ServiceForm
            formCb={() => {
              dispatch(closeModalAction()); // Ferme le modal après soumission
              triggerRefresh(); // Rafraîchit la table
            }}
          />
        ),
        options: { title: "Ajouter un service" }, // Titre affiché dans le modal
      })
    );
  };

  return (
    <>
      {/* Conteneur principal de la page */}
      <PageCanvas title="Éditer les prestations">
        {/* Section des actions de la page */}
        <PageCanvas.Actions>
          {/* Bouton pour ouvrir le modal d'ajout */}
          <Button type="primary" onClick={openModalNewService}>
            Ajouter une prestation
          </Button>
        </PageCanvas.Actions>

        {/* Section principale contenant la liste des prestations */}
        <PageCanvas.Content>
          <AdminListServices />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default AdminListServicesPage;
