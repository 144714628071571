import { Button } from "antd";
import PageCanvas from "../../common/components/Panels/PageCanvas";
import AdminListAppointment from "./AdminListAppointment";
import { closeModalAction, openModalAction } from "../../common/state/modal.slice";
import { useAppDispatch } from "../../common/hooks";
import AppointmentForm from "./Forms/AppointmentForm";

/**
 * Page principale qui affiche la liste des rendez-vous pour les administrateurs
 * et permet d'ajouter un nouveau rendez-vous.
 *
 * @returns {JSX.Element} - Le composant de la page AdminListAppointmentPage.
 */
const AdminListAppointmentPage = (): JSX.Element => {
  const dispatch = useAppDispatch();

  /**
   * Ouvre une modale pour ajouter un nouveau rendez-vous.
   * La modale contient un formulaire pour remplir les détails du rendez-vous.
   */
  const openModalNewAppointment = (): void => {
    dispatch(
      openModalAction({
        component: (
          <AppointmentForm
            formCb={() => dispatch(closeModalAction())} // Ferme la modale après soumission
          />
        ),
        options: { title: "Ajouter un rendez-vous" }, // Définit le titre de la modale
      })
    );
  };

  return (
    <>
      {/* Composant principal avec un canevas pour structurer la page */}
      <PageCanvas title="Liste des rendez-vous">
        {/* Section des actions (boutons) dans la barre d'outils */}
        <PageCanvas.Actions>
          <Button type="primary" onClick={openModalNewAppointment}>
            Ajouter un rendez-vous
          </Button>
        </PageCanvas.Actions>

        {/* Contenu principal de la page */}
        <PageCanvas.Content>
          <AdminListAppointment />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default AdminListAppointmentPage;
