import PageCanvas from "../common/components/Panels/PageCanvas";
import ContactForm from "./ContactForm";

const ContactFormPage = () => {
  return (
    <>
      <PageCanvas title="Contactez-nous" titleAlignment="center">
        <PageCanvas.Content>
          <ContactForm/>
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default ContactFormPage;
