import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import contactApi from './services/contact.api';

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

const ContactForm: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: ContactFormData) => {
    setLoading(true);

    try {
      // Envoyer les données au backend
      await contactApi.sendMessage(values.name, values.email, values.message);
      // Afficher un message de succès
      message.success('Votre message a été envoyé avec succès !');
      form.resetFields();
    } catch (error) {
      console.error(error);
      // Afficher un message d'erreur
      message.error("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-form" style={{ maxWidth: 800, margin: '0 auto', padding: 24 }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        style={{ gap: '16px' }}
      >
        <Form.Item
          label="Nom"
          name="name"
          rules={[
            { required: true, message: 'Veuillez entrer votre nom.' },
            { min: 2, message: 'Le nom doit contenir au moins 2 caractères.' },
          ]}
        >
          <Input placeholder="Votre nom" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Veuillez entrer votre email.' },
            { type: 'email', message: 'Veuillez entrer un email valide.' },
          ]}
        >
          <Input placeholder="Votre email" />
        </Form.Item>

        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: 'Veuillez entrer votre message.' }]}
        >
          <Input.TextArea rows={4} placeholder="Votre message" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: '100%' }}
          >
            Envoyer
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactForm;
