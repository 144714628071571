import { useTranslation } from "react-i18next";
import ResetPasswordForm from "./ResetPasswordForm";

/**
 * Composant `ResetPasswordPage`
 *
 * Cette page affiche un formulaire permettant aux utilisateurs de réinitialiser leur mot de passe.
 * Elle inclut un titre et intègre le composant `ResetPasswordForm` pour la gestion du formulaire.
 *
 * @composant
 * @returns {JSX.Element} Une page complète pour la réinitialisation du mot de passe.
 */
const ResetPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="titleForm">{t('UI.RESET_PASSWORD_PAGE.title')}</h1>
      <ResetPasswordForm />
    </>
  );
};

export default ResetPasswordPage;
