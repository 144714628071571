import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../common/router";
import toast from "../../../common/services/toast";
import { RootState } from "../../../common/store";
import { registerAction } from "../../state/auth.slice";

/**
 * Type des données du formulaire d'inscription.
 */
type FormType = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  password: string;
  passwordConfirm: string;
};

/**
 * Composant `RegisterForm`
 *
 * Ce composant affiche un formulaire d'inscription avec des champs pour le prénom, le nom, l'email, le mobile,
 * le mot de passe, et la confirmation du mot de passe. Il inclut une validation des critères du mot de passe et
 * gère l'inscription de l'utilisateur.
 *
 * @composant
 * @returns {JSX.Element} Un formulaire d'inscription avec validation et retour utilisateur.
 */
const RegisterForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const emailConflict = useSelector((state: RootState) => state.authService.emailConflict);
  const error = useSelector((state: RootState) => state.authService.error);

  /**
   * Suivi des critères de validation du mot de passe.
   */
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  /**
   * Suivi de la valeur actuelle du mot de passe.
   */
  const [passwordValue, setPasswordValue] = useState("");

  /**
   * Valide les critères du mot de passe et met à jour l'état correspondant.
   *
   * @param {string} password - Le mot de passe saisi par l'utilisateur.
   */
  const validatePassword = (password: string) => {
    setPasswordValue(password);
    setPasswordCriteria({
      minLength: password.length >= 8,
      hasNumber: /[0-9]/.test(password),
      hasSpecialChar: /[!@#$%^&*]/.test(password),
    });
  };

  /**
   * Gestion de la soumission du formulaire.
   *
   * @param {FormType} formData - Les données du formulaire.
   */
  const register = async (formData: FormType) => {
    await dispatch(registerAction(formData)); // Dispatch seulement, pas d'autres actions ici
  };

  useEffect(() => {
    // Vérifie si le formulaire a été soumis
    if (form.isFieldsTouched()) {
      if (emailConflict) {
        console.log("Conflit détecté : email déjà utilisé");
        toast.error("Cet email est déjà utilisé. Veuillez essayer un autre email.");
      } else if (error) {
        console.log("Erreur générale :", error);
        toast.error(error);
      } else if (!emailConflict && error === null) {
        // Condition explicite pour la redirection en cas de succès
        toast.success("Inscription réussie, veuillez vérifier votre boîte mail.");
        navigate(ROUTES.AUTH.LOGIN);
      }
    }
  }, [emailConflict, error, form, navigate]);
  
  return (
    <>
      <Form<FormType> layout="vertical" onFinish={register} form={form}>
        <Form.Item
          label={t("UI.REGISTER_PAGE.FORM.fields.firstName.label")}
          name="firstName"
          rules={[{ required: true, message: t("UI.REGISTER_PAGE.FORM.fields.firstName.required") }]}
        >
          <Input
            type="text"
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.firstName.placeholder")}
          />
        </Form.Item>
        <Form.Item
          label={t("UI.REGISTER_PAGE.FORM.fields.lastName.label")}
          name="lastName"
          rules={[{ required: true, message: t("UI.REGISTER_PAGE.FORM.fields.lastName.required") }]}
        >
          <Input
            type="text"
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.lastName.placeholder")}
          />
        </Form.Item>
        <Form.Item
          label={t("UI.REGISTER_PAGE.FORM.fields.email.label")}
          name="email"
          rules={[
            { required: true, message: t("UI.REGISTER_PAGE.FORM.fields.email.required") },
            { type: "email", message: t("UI.REGISTER_PAGE.FORM.fields.email.invalid") },
          ]}
        >
          <Input
            type="email"
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.email.placeholder")}
          />
        </Form.Item>
        <Form.Item
          label={t("UI.REGISTER_PAGE.FORM.fields.mobile.label")}
          name="mobile"
          rules={[{ required: true, message: t("UI.REGISTER_PAGE.FORM.fields.mobile.required") }]}
        >
          <Input
            type="tel"
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.mobile.placeholder")}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={t("UI.REGISTER_PAGE.FORM.fields.password.label")}
          rules={[
            { required: true, message: t("UI.REGISTER_PAGE.FORM.fields.password.required") },
          ]}
        >
          <Input.Password
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.password.placeholder")}
            onChange={(e) => validatePassword(e.target.value)}
          />
        </Form.Item>

        {passwordValue && (
          <div className="mt-2">
            <h3>{t("UI.REGISTER_PAGE.FORM.fields.password.checklist.title")}</h3>
            <ul>
              <li>
                {passwordCriteria.minLength ? (
                  <CheckCircleOutlined style={{ color: 'green', marginRight: '8px' }} />
                ) : (
                  <CloseCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                )}
                {t("UI.REGISTER_PAGE.FORM.fields.password.checklist.minLength")}
              </li>
              <li>
                {passwordCriteria.hasNumber ? (
                  <CheckCircleOutlined style={{ color: 'green', marginRight: '8px' }} />
                ) : (
                  <CloseCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                )}
                {t("UI.REGISTER_PAGE.FORM.fields.password.checklist.hasNumber")}
              </li>
              <li>
                {passwordCriteria.hasSpecialChar ? (
                  <CheckCircleOutlined style={{ color: 'green', marginRight: '8px' }} />
                ) : (
                  <CloseCircleOutlined style={{ color: 'red', marginRight: '8px' }} />
                )}
                {t("UI.REGISTER_PAGE.FORM.fields.password.checklist.hasSpecialChar")}
              </li>
            </ul>
          </div>
        )}

        <Form.Item
          name="passwordConfirm"
          label={t("UI.REGISTER_PAGE.FORM.fields.passwordConfirm.label")}
          rules={[
            {
              required: true,
              message: t("UI.REGISTER_PAGE.FORM.fields.passwordConfirm.required"),
            },
            {
              validator: (_, value) =>
                value && value === form.getFieldValue("password")
                  ? Promise.resolve()
                  : Promise.reject(new Error(t("UI.REGISTER_PAGE.FORM.fields.passwordConfirm.mismatch"))),
            },
          ]}
        >
          <Input.Password
            placeholder={t("UI.REGISTER_PAGE.FORM.fields.passwordConfirm.placeholder")}
          />
        </Form.Item>

        <Button htmlType="submit" className="w-full btn">
          {t("UI.REGISTER_PAGE.FORM.submit")}
        </Button>

        <div className="mt-8 justify-center text-center">
          <Link
            className="text-[#5B5B5B] hover:text-jb-primary font-medium font-yusei"
            to={ROUTES.AUTH.LOGIN}
          >
            {t("UI.REGISTER_PAGE.loginLink")}
          </Link>
        </div>
      </Form>
    </>
  );
};

export default RegisterForm;
