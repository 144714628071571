import React from "react";
import { Empty, Result } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AppointmentStatusEnum } from "../../Appointment/types/appointmentTypes";
import { Appointment } from "../../Appointment/types/modelAppointment";
import Fieldset from "../../common/components/Panels/Fieldset";
import { ApiListResult } from "../../common/services/types";
import esthApi from "../services/esth.api";

/**
 * Composant `AppointmentDashboardEsth`
 *
 * Ce composant affiche un tableau de bord des rendez-vous pour les esthéticiennes.
 * Les rendez-vous sont organisés par jour et affichés sous forme de cartes, avec des informations telles que le service, la date, le client, et le prix.
 *
 * @component
 * @returns {JSX.Element} Une vue d'ensemble des rendez-vous par jour pour une esthéticienne.
 */
const AppointmentDashboardEsth: React.FC = () => {
  /**
   * État local pour stocker les rendez-vous récupérés depuis l'API.
   */
  const [appointments, setAppointments] = useState<ApiListResult<Appointment>>();

  /**
   * Fonction pour récupérer les rendez-vous depuis l'API.
   */
  const fetchAppointments = async () => {
    esthApi
      .findAppointmentsEsth({}, 1, 10)
      .then((res) => setAppointments(res))
      .catch(console.log);
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  /**
   * Interface pour organiser les rendez-vous par jour.
   */
  interface AppointmentGroups {
    [day: string]: Appointment[];
  }

  /**
   * Regrouper les rendez-vous par jour.
   */
  const appointmentsByDay = appointments?.rows
    .filter(
      (appointment) =>
        appointment.status !== AppointmentStatusEnum.cancelled &&
        dayjs(appointment.date).isAfter(dayjs()) // Filtrer les rendez-vous à venir
    )
    .reduce<AppointmentGroups>((groups, appointment) => {
      const day = dayjs(appointment.date).format("dddd");
      if (!groups[day]) {
        groups[day] = [];
      }
      groups[day].push(appointment);
      return groups;
    }, {});

  /**
   * Calculer le prix total des rendez-vous pour chaque jour.
   */
  const totalPricesByDay = Object.keys(appointmentsByDay || {}).reduce(
    (acc, day) => {
      const appointmentsForDay = appointmentsByDay![day];
      const totalPrice = appointmentsForDay.reduce(
        (sum, appointment) => sum + Number(appointment.serviceId.price),
        0
      );
      acc[day] = totalPrice;
      return acc;
    },
    {} as { [day: string]: number }
  );

  return (
    <Fieldset title="Vue d'ensemble des rendez-vous : Esthéticienne">
      <div className="flex flex-col space-y-4 overflow-auto max-h-[700px] p-4">
        {appointmentsByDay && Object.keys(appointmentsByDay).length > 0 ? (
          Object.entries(appointmentsByDay).map(([day, appointments], index) => (
            <div key={`${day}-${index}`}>
              {/* En-tête du jour */}
              <div className="border-b border-gray-300 mb-2 pb-2">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg md:text-xl font-semibold capitalize">
                    {day} | {dayjs(appointments[0].date).format("DD MMM")}
                  </h2>
                  <p className="text-sm md:text-base text-gray-500">
                    {appointments.length} rendez-vous | {totalPricesByDay[day]} €
                  </p>
                </div>
              </div>

              {/* Liste des rendez-vous */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {appointments.map((appointment, index) => (
                  <div
                    key={index}
                    className="relative p-4 rounded-lg shadow-lg bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${appointment.serviceId.backgroundImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    {/* Informations superposées */}
                    <div className="text-white space-y-3">
                      <h3 className="text-xl font-semibold">
                        {appointment.serviceId.name}
                      </h3>
                      <p className="text-sm">
                        <span className="font-medium">Rendez-vous à :</span>{" "}
                        {dayjs(appointment.date).format("HH:mm")}
                      </p>
                      <p className="text-sm">
                        <span className="font-medium">Avec :</span>{" "}
                        {appointment.userId.firstName}{" "}
                        {appointment.userId.lastName}
                      </p>
                      <p className="text-sm">
                        <span className="font-medium">Téléphone :</span>{" "}
                        {appointment.userId.mobile}
                      </p>
                    </div>
                    {/* Prix */}
                    <div className="absolute bottom-4 right-4 bg-black/50 px-3 py-1 rounded-lg text-white text-sm font-bold">
                      {appointment.serviceId.price} €
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <Result
            className="mt-20"
            icon={<Empty description={null} />}
            title="Aucun rendez-vous à afficher."
          />
        )}
      </div>
    </Fieldset>
  );
};

export default AppointmentDashboardEsth;
