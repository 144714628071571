import VerifyEmail from './VerifyEmail';

/**
 * Composant `VerifyEmailPage`
 *
 * Cette page affiche le processus de vérification de l'email utilisateur. 
 * Elle inclut un titre et le composant `VerifyEmail` pour gérer la vérification.
 *
 * @composant
 * @returns {JSX.Element} Une page contenant le titre et le composant de vérification d'email.
 */
const VerifyEmailPage = () => {
  return (
    <>
      <h1 className="titleForm">Vérification de l'email</h1>
      <VerifyEmail />
    </>
  );
};

export default VerifyEmailPage;
