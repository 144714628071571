import React from 'react'
import PageCanvas from '../../common/components/Panels/PageCanvas'
import Stats from './Stats'

const StatsPage = () => {
  return (
    <PageCanvas title="Statistiques" titleAlignment="left">
      <PageCanvas.Content>
        <Stats />
      </PageCanvas.Content>
    </PageCanvas>)
}

export default StatsPage