import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AUTH_SERVICE, AuthStateType, LoginPayload, RegisterPayload } from "./types";
import { RootState } from "../../common/store";
import { AppUser } from "../../User/types";
import { User } from "../../User/types/modelUser";

type AuthServiceStateType = {
  auth: AuthStateType<AppUser> | null;
  refreshing: boolean;
  error: string | null;
  emailConflict: boolean; // Nouveau champ pour détecter les conflits d'e-mail
};

const authInitialState: AuthServiceStateType = {
  auth: null, // Aucun utilisateur connecté au démarrage
  refreshing: false,
  error: null,
  emailConflict: false, // Pas de conflit au démarrage
};

/**
 * Helper function to handle success state.
 */
const handleSuccess = <T>(
  state: AuthServiceStateType,
  payload: T
) => {
  state.auth = payload as unknown as AuthStateType<AppUser>;
  state.refreshing = false;
  state.error = null;
};

/**
 * Helper function to handle error state.
 */
const handleError = (
  state: AuthServiceStateType,
  payload: string
) => {
  state.auth = null;
  state.refreshing = false;
  state.error = payload;
};

export const authSlice = createSlice({
  name: AUTH_SERVICE,
  initialState: authInitialState,
  reducers: {
    loginAction: (state, _payload: PayloadAction<LoginPayload>) => {
      state.auth = null;
      state.refreshing = true;
      state.error = null;
    },
    loginSuccessAction: (state, { payload }: PayloadAction<AuthStateType<AppUser>>) => {
      handleSuccess(state, payload);
    },
    loginErrorAction: (state, { payload }: PayloadAction<string>) => {
      handleError(state, payload);
    },
    clearErrorAction: (state) => {
      state.error = null;
      state.emailConflict = false;
    },
    refreshTokenAction: (state) => {
      state.refreshing = true;
    },
    refreshTokenSuccessAction: (state, { payload }: PayloadAction<AuthStateType<AppUser>>) => {
      handleSuccess(state, payload);
    },
    refreshTokenErrorAction: (state) => {
      state.auth = null;
      state.refreshing = false;
    },
    registerAction: (state, _payload: PayloadAction<RegisterPayload>) => {
      state.auth = null;
      state.refreshing = true;
      state.emailConflict = false; // Réinitialiser ici
    },
    registerSuccessAction: (state, { payload }: PayloadAction<AuthStateType<AppUser>>) => {
      state.auth = payload as AuthStateType<AppUser>;
      state.refreshing = false;
      state.error = null;
      state.emailConflict = false; // Aucun conflit
    },
    registerErrorAction: (state, { payload }: PayloadAction<string>) => {
      console.log("registerErrorAction appelée avec :", payload);
      state.refreshing = false;
      state.error = payload;
      state.emailConflict = payload === "Cet email est déjà utilisé";
    },
    

    logoutAction: (state) => {
      state.auth = null;
      state.refreshing = false;
    },
    updateUserAction: (state, { payload }: PayloadAction<Partial<User>>) => {
      if (state.auth) {
        state.auth.user = { ...state.auth.user, ...payload };
      }
    },
  },
});

export const {
  loginAction,
  loginSuccessAction,
  loginErrorAction,
  clearErrorAction,
  refreshTokenAction,
  refreshTokenSuccessAction,
  refreshTokenErrorAction,
  registerAction,
  registerSuccessAction,
  registerErrorAction,
  logoutAction,
  updateUserAction,
} = authSlice.actions;

export default authSlice.reducer;

export const selectAuthService = (state: RootState) => state.authService;
export const selectAuth = createSelector(selectAuthService, (authSvc) => authSvc.auth);
export const selectAuthError = createSelector(selectAuthService, (authSvc) => authSvc.error);
export const selectIsLoggedIn = createSelector(selectAuthService, (authSvc) => authSvc.auth !== null);
export const selectUser = createSelector(selectAuth, (auth) => auth?.user);
export const selectToken = createSelector(selectAuth, (auth) => auth?.token);
