import { useTranslation } from "react-i18next";
import AskNewPasswordForm from "./AskNewPasswordForm";

/**
 * Composant `AskNewPasswordPage`
 *
 * Cette page permet aux utilisateurs de demander une réinitialisation de mot de passe.
 * Elle affiche un titre, une description, et intègre le formulaire `AskNewPasswordForm`.
 *
 * @composant
 * @returns {JSX.Element} Une page complète pour la demande de réinitialisation de mot de passe.
 */
const AskNewPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="titleForm">
        {t('UI.FORGOT_PASSWORD_PAGE.title')}
      </h1>
      <p>
        {t('UI.FORGOT_PASSWORD_PAGE.p1')}
      </p>
      <AskNewPasswordForm />
    </>
  );
};

export default AskNewPasswordPage;
