import { Button } from "antd";
import ModalConfirm from "../common/components/Modals/modal-confirm.component";
import PageCanvas from "../common/components/Panels/PageCanvas";
import { useCart } from "../common/contexts/CartContext";
import { useAppDispatch } from "../common/hooks";
import { showSuccessNotification } from "../common/services/notificationService";
import { openModalAction } from "../common/state/modal.slice";
import Cart from "./Cart";

/**
 * Composant `CartPage`
 *
 * Ce composant représente la page du panier pour la prise de rendez-vous. 
 * Il permet à l'utilisateur de visualiser le contenu de son panier, de supprimer
 * des éléments individuellement ou de vider entièrement le panier.
 *
 * @component
 * @returns {JSX.Element} La page du panier avec ses actions.
 */
const CartPage = () => {
  const { cart, deleteAllFromCart, fetchCart } = useCart();
  const dispatch = useAppDispatch();

  /**
   * Fonction pour vider tout le panier.
   *
   * Cette fonction ouvre une modal de confirmation. Si l'utilisateur confirme,
   * tous les éléments du panier sont supprimés, le panier est rafraîchi, 
   * et une notification de succès est affichée.
   */
  const handleDelete = () => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm === true) {
                await deleteAllFromCart();
                await fetchCart();
                showSuccessNotification("Votre panier à été vidé");
              }
            }}
            confirmIsDanger
            body="Êtes vous sur de vouloir vider tout votre panier ?"
          />
        ),
        options: { title: "Vider le panier" },
      })
    );
  };

  return (
    <>
      <PageCanvas title="Prise de rendez-vous">
        {/* Actions dans l'en-tête */}
        <PageCanvas.Actions>
          {cart.rows.length > 0 && (
            <Button type="primary" onClick={handleDelete}>
              Vider le panier
            </Button>
          )}
        </PageCanvas.Actions>

        {/* Contenu principal */}
        <PageCanvas.Content>
          <Cart />
        </PageCanvas.Content>
      </PageCanvas>
    </>
  );
};

export default CartPage;
