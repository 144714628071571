import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Table } from "antd";
import React, { useEffect, useState } from "react";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import Fieldset from "../../common/components/Panels/Fieldset";
import { useRefresh } from "../../common/contexts/RefreshContext";
import { useAppDispatch } from "../../common/hooks";
import { ApiListResult } from "../../common/services/types";
import { openModalAction } from "../../common/state/modal.slice";
import { Service } from "../../Services/services/types/modelService";
import adminApi from "../services/admin.api";
import {
  getServiceMenuItems,
  MenuItemContactKeysEnum,
} from "./AdminListServicesPage.misc";
import ServiceForm from "./Forms/ServiceForm";
import { useMediaQuery } from "react-responsive";
import AdminListServicesMobile from "./AdminListServicesMobile";

/**
 * Composant principal pour gérer la liste des services administrateurs.
 *
 * @returns {JSX.Element} Le composant de liste des services.
 */
const AdminListServices: React.FC = (): JSX.Element => {
  const { refreshKey, triggerRefresh } = useRefresh(); // Contexte pour gérer les rafraîchissements
  const isMobile = useMediaQuery({ query: "(max-width: 1280px)" }); // Vérifie si l'appareil est mobile

  const [services, setServices] = useState<ApiListResult<Service>>(); // État pour stocker les services récupérés

  const dispatch = useAppDispatch();

  /**
   * Récupère la liste des services depuis l'API.
   */
  const fetchServiceAdmin = async (): Promise<void> => {
    try {
      const res = await adminApi.findServicesAdmin({}, 1, 10);
      setServices(res);
    } catch (error) {
      console.error("Erreur lors de la récupération des services :", error);
    }
  };

  // Re-fetch lorsque `refreshKey` change
  useEffect(() => {
    fetchServiceAdmin();
  }, [refreshKey]);

  // Affichage spécifique pour les appareils mobiles
  if (isMobile) {
    return (
      <AdminListServicesMobile
        services={services?.rows}
        fetchServiceAdmin={fetchServiceAdmin}
      />
    );
  }

  /**
   * Ouvre le modal pour modifier un service.
   *
   * @param {Service} service - Le service à modifier.
   */
  const openModalEditService = (service: Service): void => {
    dispatch(
      openModalAction({
        component: (
          <ServiceForm
            service={service}
            formCb={() => triggerRefresh()} // Rafraîchit les données après modification
          />
        ),
        options: { title: "Modifier un service" },
      })
    );
  };

  /**
   * Ouvre le modal pour confirmer la suppression d'un service.
   *
   * @param {Service} service - Le service à supprimer.
   */
  const openModalDeleteService = (service: Service): void => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm) {
                await adminApi.deleteService(service._id!);
                fetchServiceAdmin();
              }
            }}
            confirmIsDanger
            body="Êtes-vous sûr de vouloir supprimer le service sélectionné ?"
          />
        ),
        options: { title: "Supprimer un service" },
      })
    );
  };

  /**
   * Gère le clic sur une option de menu pour modifier ou supprimer un service.
   *
   * @param {Service} service - Le service sélectionné.
   * @param {any} infos - Les informations de l'action déclenchée.
   */
  const itemClicked = (service: Service, infos: any): void => {
    switch (infos.key) {
      case MenuItemContactKeysEnum.editService:
        openModalEditService(service);
        break;
      case MenuItemContactKeysEnum.deleteService:
        openModalDeleteService(service);
        break;
      default:
        break;
    }
  };

  // Colonnes pour l'affichage dans le tableau
  const columns: any = [
    {
      title: "Image",
      dataIndex: "backgroundImage",
      key: "backgroundImage",
      render: (backgroundImage: string | undefined) => (
        <img
          src={backgroundImage}
          alt="backgroundImage"
          style={{ width: "100px", height: "100px" }}
          loading="lazy"
        />
      ),
    },
    {
      title: "Nom",
      dataIndex: "name",
      key: "name",
      render: (name: string, service: any) => (
        <Button
          type="text"
          onClick={() => {
            openModalEditService(service);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: <div className="text-center">Options</div>,
      key: "actions",
      render: (text: string, service: any) => (
        <div className="text-center">
          <Dropdown
            menu={{ items: getServiceMenuItems(service, itemClicked) }}
          >
            <MoreOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <Fieldset>
      <Table
        rowKey={"_id"} // Assure une clé unique pour chaque ligne
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={{
          hideOnSinglePage: true,
          total: services?.count,
          pageSize: 3,
        }}
        dataSource={
          services?.rows.map((r) => ({
            key: r._id, // Assure une clé unique
            ...r,
          })) || []
        }
      />
    </Fieldset>
  );
};

export default AdminListServices;
