import {
  CheckOutlined,
  ClockCircleOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Form, Image, Input, InputNumber, Space } from "antd";
import { useState } from "react";
import uploadImage from "../../../common/helpers/uploadImage";
import { useAppDispatch } from "../../../common/hooks";
import { showSuccessNotification } from "../../../common/services/notificationService";
import { CloseAnyModalAction } from "../../../common/state/modal.slice";
import { Service } from "../../../Services/services/types/modelService";
import adminApi from "../../services/admin.api";

/**
 * Props pour le composant `ServiceForm`.
 * @typedef {Object} PropsType
 * @property {Service} [service] - Service existant à modifier (facultatif).
 * @property {(service: Service) => void} [formCb] - Callback pour envoyer les données après soumission.
 */
type PropsType = {
  service?: Service;
  formCb?: (service: Service) => void;
};

/**
 * Formulaire pour la création ou la modification d'un service.
 *
 * @param {PropsType} props - Les propriétés du composant.
 * @returns {JSX.Element} - Le composant `ServiceForm`.
 */
const ServiceForm = ({ service, formCb }: PropsType): JSX.Element => {
  // État pour l'image sélectionnée
  const [backgroundImage, setBackgroundImage] = useState<File | null>(null);
  // URL de prévisualisation de l'image
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    service?.backgroundImage || null
  );
  // ID public de l'image à supprimer
  const [imageToDelete, setImageToDelete] = useState<string | null>(
    service?.backgroundImagePublicId || null
  );

  const dispatch = useAppDispatch();

  /**
   * Gère la sélection et l'upload de l'image.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - L'événement de changement de fichier.
   */
  const handleUploadProduct = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      setBackgroundImage(file);
      setPreviewUrl(URL.createObjectURL(file)); // Crée une URL locale pour l'aperçu
      setImageToDelete(service?.backgroundImagePublicId || null); // Marque l'ancienne image pour suppression
    }
  };

  /**
   * Supprime l'image sélectionnée.
   */
  const handleRemoveImage = (): void => {
    setBackgroundImage(null);
    setPreviewUrl(null);
  };

  /**
   * Gère la sauvegarde des données du service.
   *
   * @param {Service} formData - Les données saisies dans le formulaire.
   */
  const saveService = async (formData: Service): Promise<void> => {
    try {
      const updatedFormData = new FormData();

      // Ajout des champs texte et numériques
      updatedFormData.append("name", formData.name);
      updatedFormData.append("price", formData.price.toString());
      updatedFormData.append("duration", formData.duration.toString());

      // Gestion de l'image
      if (backgroundImage) {
        const uploadedImage = await uploadImage(backgroundImage);
        updatedFormData.append("backgroundImage", uploadedImage.secure_url);

        // Suppression de l'ancienne image si nécessaire
        if (imageToDelete) {
          await fetch(
            `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME_CLOUDINARY}/image/destroy`,
            {
              method: "POST",
              body: JSON.stringify({ public_id: imageToDelete }),
              headers: { "Content-Type": "application/json" },
            }
          );
        }
      }

      let response;
      if (service) {
        // Met à jour un service existant
        response = await adminApi.updateServicesAdmin(
          service._id!,
          updatedFormData as any
        );
        showSuccessNotification("Succès", "Service modifié avec succès");
      } else {
        // Crée un nouveau service
        response = await adminApi.createServicesAdmin(updatedFormData as any);
        showSuccessNotification("Succès", "Service créé avec succès");
      }

      // Ferme la modale et exécute le callback
      dispatch(CloseAnyModalAction());
      formCb && formCb(response);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du service :", error);
    }
  };

  return (
    <Form<Service>
      name="basic"
      encType="multipart/form-data"
      initialValues={service || {}} // Pré-remplit le formulaire en mode édition
      layout="vertical"
      className="form"
      onFinish={saveService}
    >
      {/* Champ pour le nom du service */}
      <Form.Item
        label="Nom du service"
        name="name"
        rules={[{ required: true, message: "Veuillez saisir le nom du service" }]}
      >
        <Input />
      </Form.Item>

      {/* Champ pour le tarif */}
      <Form.Item
        label="Tarif du service"
        name="price"
        rules={[{ required: true, message: "Veuillez saisir le tarif du service" }]}
      >
        <InputNumber addonAfter={"€"} style={{ width: "100%" }} />
      </Form.Item>

      {/* Champ pour la durée */}
      <Form.Item
        label="Durée du service"
        name="duration"
        rules={[{ required: true, message: "Veuillez saisir la durée du service" }]}
      >
        <InputNumber addonAfter={<ClockCircleOutlined />} style={{ width: "100%" }} />
      </Form.Item>

      {/* Affichage de l'image ou du bouton d'upload */}
      {previewUrl ? (
        <div className="image-preview-container">
          <Space direction="vertical" style={{ textAlign: "center" }}>
            <Image
              src={previewUrl}
              alt="Preview"
              style={{ width: "100%", height: "auto", maxHeight: "200px" }}
            />
            <Button
              type="dashed"
              icon={<DeleteOutlined />}
              onClick={handleRemoveImage}
            >
              Supprimer l'image
            </Button>
          </Space>
        </div>
      ) : (
        <label htmlFor="uploadImageInput" className="group">
          <div className="p-4 bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg h-40 w-full flex flex-col justify-center items-center cursor-pointer transition duration-200 hover:bg-gray-200 hover:border-gray-400">
            <div className="text-gray-500 group-hover:text-gray-600 flex flex-col justify-center items-center gap-3">
              <span className="text-5xl">
                <CloudDownloadOutlined />
              </span>
              <p className="text-base font-medium">Téléchargez une image</p>
              <p className="text-sm text-gray-400 group-hover:text-gray-500">
                Cliquez ou faites glisser pour télécharger
              </p>
            </div>
          </div>
          <input
            type="file"
            id="uploadImageInput"
            onChange={handleUploadProduct}
            className="hidden !w-full !h-full !absolute !opacity-0"
          />
        </label>
      )}

      {/* Bouton de soumission */}
      <Form.Item className="mt-6">
        <Button
          className="btn-void"
          htmlType="submit"
          icon={<CheckOutlined />}
        >
          Enregistrer
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ServiceForm;
