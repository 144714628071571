import React from "react";
import { Collapse, Card, Button, Empty } from "antd";
import dayjs from "dayjs";
import StatusBadge from "../../Appointment/StatusBadge";
import { Appointment } from "../../Appointment/types/modelAppointment";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import { openModalAction } from "../../common/state/modal.slice";
import { useAppDispatch } from "../../common/hooks";
import AppointmentForm from "./Forms/AppointmentForm";

interface AdminListAppointmentMobileProps {
  /**
   * Liste des rendez-vous à afficher.
   */
  appointments: Appointment[] | undefined;

  /**
   * Fonction pour recharger les rendez-vous après modification ou suppression.
   */
  fetchAppointmentsAdmin: () => void;
}

/**
 * Composant mobile pour l'affichage et la gestion des rendez-vous administrateurs.
 *
 * @param {AdminListAppointmentMobileProps} props - Propriétés du composant.
 * @returns {JSX.Element} - Composant React.
 */
const AdminListAppointmentMobile: React.FC<AdminListAppointmentMobileProps> = ({
  appointments,
  fetchAppointmentsAdmin,
}) => {
  const dispatch = useAppDispatch();

  /**
   * Ouvre un modal pour modifier un rendez-vous.
   *
   * @param {Appointment} appointment - Le rendez-vous à modifier.
   */
  const openModalEditAppointment = (appointment: Appointment) => {
    dispatch(
      openModalAction({
        component: (
          <AppointmentForm
            appointment={appointment}
            formCb={() => fetchAppointmentsAdmin()}
          />
        ),
        options: { title: "Modifier un rendez-vous" },
      })
    );
  };

  /**
   * Ouvre un modal pour confirmer et supprimer un rendez-vous.
   *
   * @param {Appointment} appointment - Le rendez-vous à supprimer.
   */
  const openModalDeleteAppointment = (appointment: Appointment) => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm) {
                console.log(`Deleted appointment with ID: ${appointment._id}`);
                fetchAppointmentsAdmin();
              }
            }}
            confirmIsDanger
            body="Êtes-vous sûr de vouloir supprimer ce rendez-vous ?"
          />
        ),
        options: { title: "Supprimer un rendez-vous" },
      })
    );
  };

  /**
   * Groupe les rendez-vous par date.
   *
   * @type {Record<string, Appointment[]>}
   */
  const groupedAppointments = appointments?.reduce((acc, appointment) => {
    const date = dayjs(appointment.date).format("DD/MM/YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(appointment);
    return acc;
  }, {} as Record<string, Appointment[]>);

  /**
   * Structure les éléments pour le composant Collapse.
   *
   * @type {Array<{ key: string; label: string; children: JSX.Element }>}
   */
  const collapseItems = Object.entries(groupedAppointments || {}).map(
    ([date, appointments]) => ({
      key: date,
      label: date,
      children: (
        <div
          className="grid grid-cols-1 gap-4"
          style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
        >
          {appointments.map((appointment) => (
            <Card
              key={appointment._id}
              title={
                appointment.serviceId
                  ? appointment.serviceId.name
                  : "Service inconnu"
              }
              extra={<StatusBadge status={appointment.status} />}
              actions={[
                <Button
                  key="edit"
                  onClick={() => openModalEditAppointment(appointment)}
                >
                  Modifier
                </Button>,
                <Button
                  key="delete"
                  danger
                  onClick={() => openModalDeleteAppointment(appointment)}
                >
                  Supprimer
                </Button>,
              ]}
            >
              <p>
                <strong>Date :</strong>{" "}
                {dayjs(appointment.date).format("DD/MM/YYYY HH:mm")}
              </p>
              <p>
                <strong>Client :</strong>{" "}
                {appointment.userId?.firstName}{" "}
                {appointment.userId?.lastName || "Inconnu"}
              </p>
            </Card>
          ))}
        </div>
      ),
    })
  );

  return (
    <div>
      {collapseItems.length > 0 ? (
        <Collapse items={collapseItems} />
      ) : (
        <Empty description="Aucun rendez-vous trouvé" />
      )}
    </div>
  );
};

export default AdminListAppointmentMobile;
