import React from "react";
import { Button } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

/**
 * Propriétés du composant `WeekNavigation`.
 */
interface WeekNavigationProps {
  /**
   * Tableau de dates représentant la semaine actuelle.
   */
  currentWeek: Date[];

  /**
   * Fonction pour naviguer vers la semaine précédente.
   *
   * @param {Date[]} currentWeek - La semaine actuelle sous forme de tableau de dates.
   */
  goToPreviousWeek: (currentWeek: Date[]) => void;

  /**
   * Fonction pour naviguer vers la semaine suivante.
   *
   * @param {Date[]} currentWeek - La semaine actuelle sous forme de tableau de dates.
   */
  goToNextWeek: (currentWeek: Date[]) => void;
}

/**
 * Composant `WeekNavigation`
 *
 * Ce composant fournit des contrôles de navigation pour se déplacer entre les semaines.
 * Il affiche la plage de dates de la semaine actuelle ainsi que des boutons pour naviguer
 * vers la semaine précédente ou suivante.
 *
 * @composant
 * @param {WeekNavigationProps} props - Les propriétés nécessaires pour rendre le composant `WeekNavigation`.
 * @returns {JSX.Element} Une barre de navigation avec des contrôles pour naviguer entre les semaines.
 */
const WeekNavigation: React.FC<WeekNavigationProps> = ({
  currentWeek,
  goToPreviousWeek,
  goToNextWeek,
}) => (
  <div className="flex justify-between w-full">
    <Button
      shape="circle"
      onClick={() => goToPreviousWeek(currentWeek)}
      icon={<CaretLeftOutlined />}
      disabled={currentWeek[0].getTime() < new Date().getTime()}
    />
    <div className="text-center font-bold">
      Semaine du{" "}
      {currentWeek[0].toLocaleDateString("fr-FR", {
        day: "numeric",
        month: "long",
      })}{" "}
      au{" "}
      {currentWeek[6].toLocaleDateString("fr-FR", {
        day: "numeric",
        month: "long",
      })}
    </div>
    <Button
      shape="circle"
      onClick={() => goToNextWeek(currentWeek)}
      icon={<CaretRightOutlined />}
    />
  </div>
);

export default WeekNavigation;
