import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { loginAction, clearErrorAction, selectAuthError } from "../../state/auth.slice";
import { showErrorNotification } from "../../../common/services/notificationService";
import { ROUTES } from "../../../common/router";

/**
 * Composant `LoginForm`
 *
 * Ce composant affiche un formulaire de connexion. Il permet à l'utilisateur de saisir ses informations d'identification
 * (email et mot de passe) et de se connecter. Il gère également les erreurs d'authentification et affiche des messages
 * d'erreur via des notifications.
 *
 * @composant
 * @returns {JSX.Element} Un formulaire de connexion avec des champs pour l'email et le mot de passe.
 */
const LoginForm = () => {
  const { t } = useTranslation();
  const error = useSelector(selectAuthError);
  const dispatch = useDispatch();

  /**
   * Type des données du formulaire.
   */
  type FormType = { email: string; password: string };

  /**
   * Fonction pour gérer la soumission du formulaire.
   *
   * @param {FormType} formData - Les données du formulaire (email et mot de passe).
   */
  const login = (formData: FormType) => {
    dispatch(loginAction(formData));
  };

  useEffect(() => {
    // Réinitialiser l'erreur lors du montage du composant
    dispatch(clearErrorAction());

    if (error) {
      showErrorNotification(t("UI.LOGIN_PAGE.ERRORS.wrongInformation"), error);
    }
  }, [error, t, dispatch]);

  return (
    <>
      <Form layout="vertical" onFinish={login} className="">
        <Form.Item
          label={t("UI.LOGIN_PAGE.FORM.fields.email.label")}
          name="email"
          rules={[
            {
              required: true,
              message: t("UI.LOGIN_PAGE.FORM.fields.email.required"),
            },
            {
              type: "email",
              message: t("UI.LOGIN_PAGE.FORM.fields.email.invalid"),
            },
          ]}
        >
          <Input
            type="email"
            placeholder={t("UI.LOGIN_PAGE.FORM.fields.email.placeholder")}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={t("UI.LOGIN_PAGE.FORM.fields.password.label")}
          rules={[
            {
              required: true,
              message: t("UI.LOGIN_PAGE.FORM.fields.password.required"),
            },
          ]}
        >
          <Input
            type="password"
            placeholder={t("UI.LOGIN_PAGE.FORM.fields.password.placeholder")}
          />
        </Form.Item>

        <Button htmlType="submit" className="w-full btn">
          {t("UI.LOGIN_PAGE.FORM.submit")}
        </Button>
        <div className="flex flex-col space-y-4 mt-8">
          <div className="justify-center text-center">
            <Link
              className="text-black hover:text-jb-primary font-medium font-yusei"
              to={ROUTES.AUTH.RESET_PASSWORD}
            >
              {t("UI.LOGIN_PAGE.FORGOT_PASSWORD")}
            </Link>
          </div>
          <div className="justify-center text-center">
            <Link
              className="text-[#5B5B5B] hover:text-jb-primary font-medium font-yusei"
              to={ROUTES.AUTH.REGISTER}
            >
              {t("UI.LOGIN_PAGE.NO_ACCOUNT")}
              <span className="text-black"> {t("UI.LOGIN_PAGE.REGISTER")}</span>
            </Link>
          </div>
        </div>
      </Form>
    </>
  );
};

export default LoginForm;
