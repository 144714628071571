import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../common/router";
import { showErrorNotification, showSuccessNotification } from "../../../common/services/notificationService";
import authApi from "../../services/auth.api";

/**
 * Composant `AskNewPasswordForm`
 *
 * Ce composant affiche un formulaire permettant aux utilisateurs de demander une réinitialisation de mot de passe.
 * Lorsqu'un email valide est soumis, une notification de succès est affichée, et une requête est envoyée à l'API.
 * En cas d'erreur, une notification d'erreur est affichée.
 *
 * @composant
 * @returns {JSX.Element} Un formulaire de demande de réinitialisation de mot de passe.
 */
const AskNewPasswordForm = () => {
  const { t } = useTranslation();

  /**
   * Gestion de l'envoi du formulaire.
   *
   * @param {string} data - L'email soumis par l'utilisateur.
   */
  const handleFinish = (data: string) => {
    if (data) {
      authApi.requestPasswordReset(data);
      showSuccessNotification(
        "Succès",
        "Un email vous a été envoyé pour réinitialiser votre mot de passe"
      );
    } else {
      showErrorNotification("Erreur", "Une erreur est survenue");
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        className=""
        onFinish={handleFinish}
      >
        <Form.Item
          label={t('UI.LOGIN_PAGE.FORM.fields.email.label')}
          name="email"
        >
          <Input type="email" />
        </Form.Item>
        <div className="flex space-normal items-center space-x-3">
          <Button htmlType="submit" type="primary">
            {t('UI.FORGOT_PASSWORD_PAGE.FORM.submit')}
          </Button>
          <Link
            className="text-black hover:text-jb-primary font-medium font-yusei"
            to={ROUTES.AUTH.LOGIN}
          >
            Me connecter
          </Link>
        </div>
      </Form>
    </>
  );
};

export default AskNewPasswordForm;
