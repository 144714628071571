import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "tailwindcss/tailwind.css";
import { Cart } from "../../Cart/types/modelCart";
import { Service } from "../../Services/services/types/modelService";
import { useAppDispatch } from "../../common/hooks";
import { ApiListResult } from "../../common/services/types";
import appointmentApi from "../services/appointment.api";
import { Appointment } from "../types/modelAppointment";
import AvailableHours from "./AvailableHours";
import "./Calendar.css";
import CalendarMobile from "./CalendarMobile";
import WeekHeader from "./WeekHeader";
import WeekNavigation from "./WeekNavigation";
import { daysOfWeek, getWeekDays, goToNextWeek, goToPreviousWeek, handleHourClick, handleWeekChange } from "./dateUtils";

/**
 * Props pour le composant `Calendar`.
 */
type CalendarProps = {
  /**
   * Fonction de rappel appelée lorsqu'une date est sélectionnée.
   *
   * @param {Date} date - La date sélectionnée.
   */
  onDateSelect: (date: Date) => void;

  /**
   * Fonction de rappel appelée lorsqu'une date et une heure sont sélectionnées.
   *
   * @param {Date} date - La date et l'heure sélectionnées.
   */
  onDateTimeSelect: (date: Date) => void;

  /**
   * Le service pour lequel les rendez-vous sont planifiés.
   */
  service: Service;

  /**
   * Le panier de l'utilisateur contenant les réservations ou les éléments sélectionnés.
   */
  cart: Cart;
};

/**
 * Composant `Calendar`
 *
 * Ce composant affiche un calendrier hebdomadaire qui permet de visualiser les créneaux disponibles
 * pour un service donné. Il s'adapte à différentes tailles d'écran en proposant une vue optimisée
 * pour les appareils mobiles.
 *
 * @component
 * @param {CalendarProps} props - Les propriétés nécessaires pour rendre le composant `Calendar`.
 * @returns {JSX.Element} Une vue calendrier affichant les créneaux disponibles pour les rendez-vous.
 */
const Calendar: React.FC<CalendarProps> = ({
  onDateSelect,
  onDateTimeSelect,
  service,
  cart,
}) => {
  const [appointments, setAppointments] =
    useState<ApiListResult<Appointment>>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedHour, setSelectedHour] = useState<string | null>(null);

  const [currentWeek, setCurrentWeek] = useState<Date[]>(
    getWeekDays(new Date())
  );

  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 1280px)" });

  /**
   * Récupère les rendez-vous à partir de l'API pour le service actuel.
   */
  const fetchAppointments = async () => {
    appointmentApi
      .findAppointmentsForCreateAppointment({}, 1, 10)
      .then((res) => setAppointments(res))
      .catch(console.log);
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  if (isMobile) {
    return (
      <>
        <CalendarMobile
          currentWeek={currentWeek}
          daysOfWeek={daysOfWeek}
          serviceDuration={service.duration}
          appointments={appointments?.rows ?? []}
          selectedHour={selectedHour}
          selectedDate={selectedDate}
          handleHourClick={(hour: string, day: Date) =>
            handleHourClick(hour, day, setSelectedDate, setSelectedHour, onDateSelect, onDateTimeSelect, dispatch, cart._id!)
          }
          handleWeekChange={handleWeekChange}
          goToPreviousWeek={goToPreviousWeek}
          goToNextWeek={goToNextWeek}
          setCurrentWeek={setCurrentWeek}
          setSelectedDate={setSelectedDate}
        />
      </>
    );
  }

  return (
    <div className="mt-4">
      <WeekNavigation
        goToPreviousWeek={() =>
          handleWeekChange(goToPreviousWeek(currentWeek), setCurrentWeek, setSelectedDate)
        }
        goToNextWeek={() =>
          handleWeekChange(goToNextWeek(currentWeek), setCurrentWeek, setSelectedDate)
        }
        currentWeek={currentWeek}
      />
      <div className="flex flex-col items-center">
        <div>
          <WeekHeader currentWeek={currentWeek} daysOfWeek={daysOfWeek} />
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 2xl:grid-cols-7 overflow-y-auto max-h-[500px]">
            {currentWeek.map((date, index) => (
              <AvailableHours
                key={`${date.toISOString()}-${index}`}
                date={date}
                daysOfWeek={daysOfWeek}
                serviceDuration={service.duration}
                appointments={appointments?.rows ?? []}
                selectedHour={selectedHour}
                selectedDate={selectedDate}
                handleHourClick={(hour: string, day: Date) =>
                  handleHourClick(hour, day, setSelectedDate, setSelectedHour, onDateSelect, onDateTimeSelect, dispatch, cart._id!)
                }
                dayIndex={currentWeek.indexOf(date)}
                gridColumns={7}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
