import { Table } from "antd";
import { ColumnsType } from "antd/es/table/interface";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import Fieldset from "../../common/components/Panels/Fieldset";
import { useRefresh } from "../../common/contexts/RefreshContext";
import { ApiListResult } from "../../common/services/types";
import { openModalAction } from "../../common/state/modal.slice";
import { User } from "../../User/types/modelUser";
import adminApi from "../services/admin.api";
import AdminListUserMobile from "./AdminListUserMobile";
import { MenuItemContactKeysEnum } from "./AdminListUserPage.misc";
import UserForm from "./Forms/UserForm";
import UserResume from "./UserResume";

/**
 * Type definition for modal actions used in user management.
 * @typedef {Function} ModalActionType
 * @param {User} user - The user object to perform the action on.
 */
type ModalActionType = (user: User) => void;

/**
 * Type definition for dispatch functions.
 * @typedef {Function} DispatchFunction
 * @param {any} action - The Redux action to dispatch.
 */
type DispatchFunction = (action: any) => void;

/**
 * Type definition for refresh functions.
 * @typedef {Function} TriggerRefreshFunction
 * @returns {void}
 */
type TriggerRefreshFunction = () => void;

/**
 * Opens a modal to edit user information.
 * @param {User} user - The user to edit.
 * @param {DispatchFunction} dispatch - The Redux dispatch function.
 * @param {TriggerRefreshFunction} triggerRefresh - Function to refresh user data.
 */
export const openModalContact = (
  user: User,
  dispatch: DispatchFunction,
  triggerRefresh: TriggerRefreshFunction
) => {
  dispatch(
    openModalAction({
      component: <UserForm user={user} formCb={() => triggerRefresh()} />,
      options: { title: "Modifier un contact" },
    })
  );
};

/**
 * Opens a modal to confirm and delete a user.
 * @param {User} user - The user to delete.
 * @param {DispatchFunction} dispatch - The Redux dispatch function.
 * @param {TriggerRefreshFunction} fetchUsersAdmin - Function to refresh user data after deletion.
 */
export const openModalDeleteUser = (
  user: User,
  dispatch: DispatchFunction,
  fetchUsersAdmin: TriggerRefreshFunction
) => {
  dispatch(
    openModalAction({
      component: (
        <ModalConfirm
          formCb={async (confirm) => {
            if (confirm) {
              await adminApi.deleteUser(user._id ?? "");
              fetchUsersAdmin();
            }
          }}
          confirmIsDanger
          body="Êtes-vous sûr de vouloir supprimer ce contact ?"
        />
      ),
      options: { title: "Supprimer un contact" },
    })
  );
};

/**
 * Handles item click events to trigger actions like edit or delete.
 * @param {User} user - The user object associated with the action.
 * @param {any} infos - Additional information about the action.
 * @param {ModalActionType} openModalContact - Function to open the edit modal.
 * @param {ModalActionType} openModalDeleteUser - Function to open the delete confirmation modal.
 */
export const itemClicked = (
  user: User,
  infos: any,
  openModalContact: ModalActionType,
  openModalDeleteUser: ModalActionType
) => {
  switch (infos.key) {
    case MenuItemContactKeysEnum.editUser:
      openModalContact(user);
      break;
    case MenuItemContactKeysEnum.deleteUser:
      openModalDeleteUser(user);
      break;
    default:
      break;
  }
};

/**
 * Renders the admin user list component with options to manage each user.
 * Automatically switches to a mobile-friendly layout on smaller screens.
 * @component
 * @returns {JSX.Element} The admin user list component.
 */
const AdminListUser: React.FC = () => {
  const [accounts, setUsers] = useState<ApiListResult<User>>();
  const { refreshKey } = useRefresh();
  const isMobile = useMediaQuery({ query: "(max-width: 1280px)" });

  /**
   * Fetches the list of users for the admin dashboard.
   */
  const fetchUsersAdmin = async () => {
    adminApi
      .findUsersAdmin({}, 1, 10)
      .then((res) => setUsers(res))
      .catch(console.log);
  };

  // Fetch user data whenever the refresh key changes.
  useEffect(() => {
    fetchUsersAdmin();
  }, [refreshKey]);

  // Render the mobile version if the screen size is small.
  if (isMobile) {
    return (
      <AdminListUserMobile
        accounts={accounts?.rows}
        fetchUsersAdmin={fetchUsersAdmin}
      />
    );
  }

  // Define the columns for the desktop user table.
  const columns: ColumnsType<any> = [
    {
      title: "Nom",
      key: "lastName",
      render: (user: any) => (
        <div>
          <UserResume user={user} />
        </div>
      ),
    },
    {
      title: "Téléphone",
      dataIndex: "mobile",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
  ];

  return (
    <Fieldset title="Vue d'ensemble des rendez-vous : Admin">
      <Table
        rowKey={(record) => record._id}
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={{
          hideOnSinglePage: true,
          total: accounts?.count,
          pageSize: 5,
        }}
        dataSource={accounts?.rows || []}
      />
    </Fieldset>
  );
};

export default AdminListUser;
