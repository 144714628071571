import { useEffect, useState } from "react";
import { Card, List } from "antd";
import {
  ArcElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import appointmentApi from "../../Appointment/services/appointment.api";

// Enregistrement des éléments nécessaires
Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
);

const Stats = () => {
  const [frequentClients, setFrequentClients] = useState<any[]>([]);
  const [frequentServicesData, setFrequentServicesData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string[];
      hoverBackgroundColor: string[];
    }[];
  }>({
    labels: [],
    datasets: [
      {
        label: "Services les plus réservés",
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  });

  const fetchFrequentServices = async () => {
    try {
      const response = await appointmentApi.getFrequentServices();
      const services = response.rows || [];

      const labels = services.map((service: any) => service.name);
      const data = services.map((service: any) => service.count);

      setFrequentServicesData({
        labels,
        datasets: [
          {
            label: "Services les plus réservés",
            data,
            backgroundColor: [
              "#4CAF50",
              "#2196F3",
              "#FFC107",
              "#E91E63",
              "#FF5722",
              "#9C27B0",
            ],
            hoverBackgroundColor: [
              "#388E3C",
              "#1976D2",
              "#FFA000",
              "#C2185B",
              "#D84315",
              "#7B1FA2",
            ],
          },
        ],
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };

  const fetchFrequentClients = async () => {
    try {
      const response = await appointmentApi.getFrequentClients();
      const clients = response.rows || [];
      setFrequentClients(clients);
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };

  useEffect(() => {
    fetchFrequentServices();
  }, []);

  useEffect(() => {
    fetchFrequentClients();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row lg:gap-6 gap-4 justify-center items-stretch">
      {frequentServicesData.labels.length || frequentClients.length > 0 ? (
        <>
          {/* Card for Doughnut Chart */}
          <div className="w-full lg:w-1/2 flex flex-col items-center">
            <Card
              title="Services les plus réservés"
              className="shadow-md w-full h-full"
            >
              <div className="flex justify-center">
                <div style={{ width: "100%", height: "300px" }}>
                  <Doughnut
                    data={frequentServicesData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </div>
            </Card>
          </div>

          {/* Card for Frequent Clients List */}
          <div className="w-full lg:w-1/2 gap-2 flex flex-col items-center">
            <Card
              title="Clients les plus fréquents"
              className="shadow-md w-full h-full flex-grow"
            >
              <List
                itemLayout="horizontal"
                dataSource={frequentClients}
                renderItem={(client: any) => (
                  <List.Item>
                    <List.Item.Meta
                      title={`${client.firstName} ${client.lastName}`}
                      description={`Rendez-vous : ${client.count}`}
                    />
                  </List.Item>
                )}
              />
            </Card>
            
          </div>
        </>
      ) : (
        <p className="text-center w-full">Chargement des données...</p>
      )}
    </div>
  );
};

export default Stats;
