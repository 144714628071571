import { useTranslation } from "react-i18next";
import RegisterForm from './RegisterForm';

/**
 * Composant `RegisterPage`
 *
 * Cette page affiche un formulaire d'inscription permettant aux utilisateurs de créer un compte.
 * Elle inclut un titre et intègre le formulaire `RegisterForm`.
 *
 * @composant
 * @returns {JSX.Element} Une page complète pour l'inscription des utilisateurs.
 */
const RegisterPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="titleForm">{t('UI.REGISTER_PAGE.title')}</h1>
      <RegisterForm />
    </>
  );
};

export default RegisterPage;
