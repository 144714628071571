import { buildFindQuery } from "../tools/global-tools";
import apiClient from "./apiClient";
import { trackProgress } from "./trackProgress";
import { ApiListResult } from "./types";

/**
 * Classe générique pour gérer les requêtes API CRUD pour divers types de ressources.
 * @template K Le type de la ressource à gérer.
 */
class ApiService<K> {
  /**
   * Chemin de base de l'API pour la ressource, à définir lors de l'instanciation.
   */
  path!: string;

  /**
   * Définition des zones de suivi du progrès des requêtes.
   * Permet de tracer l'état des opérations via `trackProgress`.
   */
  public trackProgressAreas = {
    find: `GET_${this.path}`,
    get: `GET_${this.path}:id`,
    update: `PUT_${this.path}:id`,
    create: `POST_${this.path}`,
    delete: `DELETE_${this.path}:id`,
    custom: (method: string) => `${method.toUpperCase()}_custom_${this.path}:id`,
  };

  /**
   * Constructeur pour configurer le chemin d'accès API.
   * @param path - Le chemin relatif de la ressource.
   */
  constructor(path: string) {
    // Assurer que le chemin commence par un slash.
    if (path[0] !== "/") this.path = "/" + path;
    // Assurer que le chemin se termine par un slash.
    if (path[path.length - 1] !== "/") this.path += "/";
  }

  /**
   * Rechercher une liste de ressources avec pagination.
   * @param query - Les critères de recherche.
   * @param page - Le numéro de page pour la pagination.
   * @param pageSize - Le nombre d'éléments par page.
   * @returns Une promesse contenant les résultats paginés.
   */
  protected find = ({
    query = {},
    page = 1,
    pageSize = 10,
  }): Promise<ApiListResult<K>> => {
    const myQuery = { ...query }; // Clonage de la requête
    const queryString = buildFindQuery(myQuery); // Génère une chaîne de requête
    return trackProgress(
      apiClient.get<ApiListResult<K>>(
        `${this.path.replace(/\/$/, "")}?${queryString}&page=${page}&limit=${pageSize}`
      ),
      this.trackProgressAreas.find
    ).then((r) => r.data);
  };

  /**
   * Récupérer une ressource unique par son ID.
   * @param id - L'identifiant unique de la ressource.
   * @returns Une promesse contenant les données de la ressource.
   */
  protected get = (id: string): Promise<K> => {
    return trackProgress(
      apiClient.get<K>(`${this.path}${id}`),
      this.trackProgressAreas.get
    ).then((r) => r.data);
  };

  /**
   * Créer une nouvelle ressource.
   * @param data - Les données pour créer la ressource.
   * @returns Une promesse contenant la ressource créée.
   */
  protected create = (data: Partial<K>) => {
    return trackProgress(
      apiClient.post<K>(`${this.path}`, data),
      this.trackProgressAreas.create
    ).then((r) => r.data);
  };

  /**
   * Mettre à jour une ressource existante.
   * @param id - L'identifiant de la ressource à mettre à jour.
   * @param data - Les données mises à jour.
   * @returns Une promesse contenant la ressource mise à jour.
   */
  protected update = (id: string, data: Partial<K>) => {
    return trackProgress(
      apiClient.put<K>(`${this.path}${id}`, data),
      this.trackProgressAreas.update
    ).then((response) => response.data);
  };

  /**
   * Supprimer une ressource.
   * @param id - L'identifiant de la ressource à supprimer.
   * @param data - Les données optionnelles pour la suppression.
   * @returns Une promesse contenant la réponse de suppression.
   */
  protected delete = (id: string, data?: Partial<K>) => {
    return trackProgress(
      apiClient.delete<K>(`${this.path}${id}`, data),
      this.trackProgressAreas.delete
    ).then((response) => response.data);
  };

  /**
   * Exécuter une requête personnalisée.
   * @template R Le type de la réponse attendue.
   * @param method - La méthode HTTP à utiliser (GET, PUT, POST, DELETE).
   * @param path - Le chemin relatif de l'API.
   * @param params - Les paramètres pour les segments dynamiques de l'URL.
   * @param data - Les données pour les requêtes PUT ou POST.
   * @param headers - Les en-têtes personnalisés.
   * @returns Une promesse contenant les données de la réponse.
   */
  protected custom = <R>(
    method: "get" | "put" | "post" | "delete" = "get",
    path: string,
    params?: any,
    data?: Partial<K> | any,
    headers?: Record<string, string>
  ) => {
    const paramNames = path.match(/:([^/?]+)/g); // Trouver les paramètres dynamiques
    let query = path;

    // Remplacer les paramètres dynamiques par leurs valeurs
    paramNames?.forEach(
      (paramName) => (query = query.replace(paramName, params[paramName.replace(":", "")]))
    );

    // Déterminer si la requête utilise des données
    const call = ["put", "post"].includes(method)
      ? apiClient[method]<R>(`${this.path}${query}`, data, { headers })
      : apiClient[method]<R>(`${this.path}${query}`, { headers });

    return trackProgress(call, this.trackProgressAreas.custom(method)).then(
      (response) => response.data
    );
  };
}

export default ApiService;
