import React, { useEffect, useState } from "react";
import { Button } from "antd";
import cartApi from "../../Cart/services/cart.api";
import { useCart } from "../../common/contexts/CartContext";
import { ApiListResult } from "../../common/services/types";
import { Appointment } from "../types/modelAppointment";
import { getAvailableHours, isHourAvailable } from "./dateUtils";

/**
 * Props pour le composant `AvailableHours`.
 */
type AvailableHoursProps = {
  /**
   * La date sélectionnée pour laquelle les heures disponibles seront affichées.
   */
  date: Date;

  /**
   * Tableau des noms des jours de la semaine (par exemple : ["Dimanche", "Lundi", ...]).
   */
  daysOfWeek: string[];
  
  /**
   * La durée du service en minutes.
   */
  serviceDuration: number;

  /**
   * Liste des rendez-vous existants, utilisée pour déterminer la disponibilité des créneaux horaires.
   */
  appointments: ApiListResult<Appointment>["rows"];

  /**
   * L'heure actuellement sélectionnée (le cas échéant).
   */
  selectedHour: string | null;

  /**
   * La date actuellement sélectionnée (le cas échéant).
   */
  selectedDate: Date | null;

  /**
   * Fonction de rappel appelée lorsqu'un créneau horaire est cliqué.
   *
   * @param {string} hour - Le créneau horaire cliqué.
   * @param {Date} date - La date associée au créneau horaire.
   */
  handleHourClick: (hour: string, date: Date) => void;

  /**
   * L'index du jour à afficher, utilisé pour le style et la disposition.
   */
  dayIndex: number;

  /**
   * Le nombre de colonnes dans la grille pour afficher les heures disponibles.
   */
  gridColumns: number;
};

/**
 * Composant `AvailableHours`
 *
 * Ce composant affiche les créneaux horaires disponibles pour la prise de rendez-vous.
 * Il calcule dynamiquement les créneaux en fonction de la date fournie, de la durée du service,
 * des rendez-vous existants, et des créneaux sélectionnés par l'utilisateur.
 *
 * @param {AvailableHoursProps} props - Les propriétés nécessaires pour rendre le composant `AvailableHours`.
 * @returns {JSX.Element} Une grille affichant les créneaux horaires disponibles pour la prise de rendez-vous.
 */
const AvailableHours: React.FC<AvailableHoursProps> = ({
  date,
  daysOfWeek,
  serviceDuration,
  appointments,
  selectedHour,
  selectedDate,
  handleHourClick,
  dayIndex,
  gridColumns,
}) => {
  const { cart } = useCart();
  const [selectedSlots, setSelectedSlots] = useState<Date[]>([]);

  useEffect(() => {
    /**
     * Récupère les créneaux sélectionnés dans le panier de l'utilisateur.
     */
    const fetchSelectedSlots = async () => {
      try {
        const res = await cartApi.fetchCart();
        const selectedSlots = res.rows.map((item) => new Date(item.date));
        setSelectedSlots(selectedSlots);
      } catch (error) {
        console.error("Erreur lors de la récupération du panier :", error);
      }
    };

    fetchSelectedSlots();
  }, [cart]);

  const dayOfWeekLabel = daysOfWeek[date.getDay()];
  const availableHours = getAvailableHours(dayOfWeekLabel, serviceDuration, date);

  return (
    <div
      className={`col-span-1 day-${dayIndex}`}
      style={{ gridColumn: dayIndex + 1 }}
    >
      <div className="hours grid grid-cols-1 gap-x-4 gap-y-2 p-2">
        {availableHours.map((hour) => {
          const isSlotAvailable = isHourAvailable(
            hour,
            date,
            daysOfWeek,
            serviceDuration,
            appointments,
            selectedSlots
          );

          return (
            <Button
              key={hour}
              type={
                selectedHour === hour &&
                selectedDate &&
                selectedDate.toLocaleDateString() === date.toLocaleDateString()
                  ? "primary"
                  : "default"
              }
              disabled={!isSlotAvailable}
              onClick={isSlotAvailable ? () => handleHourClick(hour, date) : undefined}
              className={`cursor-pointer font-yusei text-center text-base rounded shadow-md ${
                selectedHour === hour &&
                selectedDate &&
                selectedDate.toLocaleDateString() === date.toLocaleDateString()
                  ? "bg-jb-primary"
                  : !isSlotAvailable
                  ? "cursor-not-allowed"
                  : "hover:bg-gray-100"
              }`}
            >
              {hour}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default AvailableHours;
