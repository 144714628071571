import { defaultPagination } from "../../common/app-constants";
import ApiService from "../../common/services/apiService";
import { ApiListResult } from "../../common/services/types";
import { IAppointment } from "../types/appointmentTypes";
import { Appointment } from "../types/modelAppointment";

const APPOINTMENT_PATH_API = "appointments";

/**
 * AppointmentApi
 *
 * Service permettant d'interagir avec l'API des rendez-vous (`appointments`).
 * Hérite de la classe générique `ApiService` et propose des méthodes pour gérer les rendez-vous,
 * comme la récupération, la création ou l'annulation.
 */
class AppointmentApi extends ApiService<IAppointment> {
  /**
   * Constructeur de la classe `AppointmentApi`.
   * Définit le chemin de base pour les requêtes relatives aux rendez-vous.
   */
  constructor() {
    super(APPOINTMENT_PATH_API);
  }

  /**
   * Récupère une liste paginée de rendez-vous en fonction des critères de recherche.
   *
   * @param {object} query - Les paramètres de recherche pour filtrer les rendez-vous.
   * @param {number} page - Le numéro de la page à récupérer (par défaut : `defaultPagination.page`).
   * @param {number} pageSize - Le nombre d'éléments par page (par défaut : `defaultPagination.pageSize`).
   * @returns {Promise<{ count: number; rows: Appointment[] }>} 
   * Une promesse résolvant un objet contenant le nombre total et une liste de rendez-vous (`Appointment`).
   * @throws Lance une erreur si la structure de la réponse est invalide.
   */
  findAppointments = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Appointment[] }> => {
    return this.find({ query, page, pageSize }).then((res) => {
      if (!res || !res.rows) {
        throw new Error("Structure de réponse invalide");
      }
      return {
        count: res.count,
        rows: res.rows.map((r) => new Appointment(r)),
      };
    });
  };

  /**
   * Crée un nouveau rendez-vous.
   *
   * @param {IAppointment} appointment - Les données du rendez-vous à créer.
   * @returns {Promise<Appointment>} Une promesse résolvant l'instance de rendez-vous créé (`Appointment`).
   */
  createAppointment = (appointment: IAppointment): Promise<Appointment> => {
    return this.create(appointment).then((r) => new Appointment(r));
  };

  /**
   * Récupère un rendez-vous spécifique via son ID.
   *
   * @param {string} appointmentId - L'identifiant du rendez-vous à récupérer.
   * @returns {Promise<Appointment>} Une promesse résolvant l'instance du rendez-vous récupéré (`Appointment`).
   */
  getAppointment = (appointmentId: string): Promise<Appointment> => {
    return this.get(appointmentId).then((r) => new Appointment(r));
  };

  /**
   * Récupère une liste paginée de rendez-vous pour la création de nouveaux rendez-vous.
   *
   * @param {object} query - Les paramètres de recherche pour filtrer les rendez-vous.
   * @param {number} page - Le numéro de la page à récupérer (par défaut : `defaultPagination.page`).
   * @param {number} pageSize - Le nombre d'éléments par page (par défaut : `defaultPagination.pageSize`).
   * @returns {Promise<{ count: number; rows: Appointment[] }>} 
   * Une promesse résolvant un objet contenant le nombre total et une liste de rendez-vous (`Appointment`).
   */
  findAppointmentsForCreateAppointment = (
    query = {},
    page = defaultPagination.page,
    pageSize = defaultPagination.pageSize
  ): Promise<{ count: number; rows: Appointment[] }> => {
    return this.custom<ApiListResult<Appointment>>(
      "get",
      `getAllAppointmentsForCreateAppointment`,
      {
        query,
        page,
        pageSize,
      }
    ).then((res) => {
      return {
        count: res.count,
        rows: res.rows.map((r) => new Appointment(r)),
      };
    });
  };

  /**
   * Annule un rendez-vous existant.
   *
   * @param {string} appointmentId - L'identifiant du rendez-vous à annuler.
   * @param {string} status - Le statut d'annulation à appliquer.
   * @returns {Promise<Appointment>} Une promesse résolvant l'instance du rendez-vous annulé (`Appointment`).
   */
  cancelAppointment = (
    appointmentId: string,
    status: string
  ): Promise<Appointment> => {
    return this.custom<Appointment>(
      "put",
      `/cancel/${appointmentId}`,
      {},
      { status }
    ).then((r) => new Appointment(r));
  };

  getFrequentServices = () => {
    // find
    return this.custom<ApiListResult<Appointment>>(
      "get",
      `frequent-services`
    ).then((res) => {
      console.log(res);
      return {
        count: res.count,
        rows: res.rows
      };
    });
  };

  getFrequentClients = () => {
    // find
    return this.custom<ApiListResult<Appointment>>(
      "get",
      `frequent-clients`
    ).then((res) => {
      console.log(res);
      return {
        count: res.count,
        rows: res.rows
      };
    });
  };
}

const appointmentApi = new AppointmentApi();

export default appointmentApi;
