import apiClient from "../../common/services/apiClient";
import { trackProgress } from "../../common/services/trackProgress";
import { AppAuth } from "../types";
import { User } from "../../User/types/modelUser";

const AUTH_PATH_API = "auth";

/**
 * Classe `AuthApi`
 *
 * Cette classe gère les appels à l'API pour les opérations d'authentification,
 * telles que la connexion, l'inscription, la gestion des tokens, et la réinitialisation de mot de passe.
 */
class AuthApi {
  /**
   * Effectue une connexion utilisateur.
   *
   * @param {string} email - L'email de l'utilisateur.
   * @param {string} password - Le mot de passe de l'utilisateur.
   * @returns {Promise<{ token: string; user: User }>} Une promesse contenant le token et l'utilisateur connecté.
   */
  login(email: string, password: string) {
    return trackProgress(
      apiClient.post<AppAuth>(`${AUTH_PATH_API}/login`, { email, password }),
      `POST_${AUTH_PATH_API}login`
    ).then((r) => ({
      token: r.data.token,
      user: new User(r.data.user),
    }));
  }

  /**
   * Rafraîchit le token d'authentification.
   *
   * @param {string} token - Le token expiré à rafraîchir.
   * @returns {Promise<{ token: string; user: User }>} Une promesse contenant le nouveau token et l'utilisateur.
   */
  refreshToken(token: string) {
    return trackProgress(
      apiClient.post<AppAuth>(`${AUTH_PATH_API}/refreshToken`, { token }),
      `POST_${AUTH_PATH_API}refreshToken`
    ).then((r) => ({
      token: r.data.token,
      user: new User(r.data.user),
    }));
  }

  /**
   * Inscrit un nouvel utilisateur.
   *
   * @param {string} firstName - Le prénom de l'utilisateur.
   * @param {string} lastName - Le nom de famille de l'utilisateur.
   * @param {string} mobile - Le numéro de mobile de l'utilisateur.
   * @param {string} email - L'email de l'utilisateur.
   * @param {string} password - Le mot de passe de l'utilisateur.
   * @returns {Promise<{ token: string; user: User }>} Une promesse contenant le token et l'utilisateur inscrit.
   */
  register(
    firstName: string,
    lastName: string,
    mobile: string,
    email: string,
    password: string
  ) {
    return trackProgress(
      apiClient.post<AppAuth>(`${AUTH_PATH_API}/signup`, {
        firstName,
        lastName,
        mobile,
        email,
        password,
      }),
      `POST_${AUTH_PATH_API}signup`
    ).then((r) => ({
      token: r.data.token,
      user: new User(r.data.user),
    }));
  }

  /**
   * Vérifie l'email d'un utilisateur via un token.
   *
   * @param {string} token - Le token de vérification.
   * @returns {Promise<any>} Une promesse indiquant le succès ou l'échec de la vérification.
   */
  verifyEmail(token: string) {
    return trackProgress(
      apiClient.get(`${AUTH_PATH_API}/verify-email?token=${token}`),
      `GET_${AUTH_PATH_API}verify-email`
    );
  }

  /**
   * Demande une réinitialisation de mot de passe pour un utilisateur.
   *
   * @param {string} email - L'email de l'utilisateur.
   * @returns {Promise<any>} Une promesse indiquant le succès ou l'échec de la demande.
   */
  requestPasswordReset(email: string) {
    return trackProgress(
      apiClient.post(`${AUTH_PATH_API}/request-password-reset`, email),
      `POST_${AUTH_PATH_API}request-password-reset`
    );
  }

  /**
   * Réinitialise le mot de passe de l'utilisateur.
   *
   * @param {string} token - Le token de réinitialisation.
   * @param {string} password - Le nouveau mot de passe.
   * @returns {Promise<any>} Une promesse indiquant le succès ou l'échec de la réinitialisation.
   */
  resetPassword(token: string, password: string) {
    return trackProgress(
      apiClient.post(`${AUTH_PATH_API}/reset-password`, { token, password }),
      `POST_${AUTH_PATH_API}reset-password`
    );
  }
}

const authApi = new AuthApi();
export default authApi;
