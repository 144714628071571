import { App, ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import antdConfig from "./antd-theme";
import LoadingScreen from "./common/components/LoadingScreen";
import router from "./common/router";
import "./common/services/i18next";
import { store } from "./common/store";
import StorePersistGate from "./common/store/StorePersistGate";
import "./dayConfig.ts";
import "./index.css";

import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import AxeptioScriptLoader from "./AxeptioScriptLoader";
import { CartProvider } from "./common/contexts/CartContext";
import { RefreshProvider } from "./common/contexts/RefreshContext";
import React from "react";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider theme={antdConfig}>
      <App>
        <Provider store={store.default}>
          <ErrorBoundary>
            <StorePersistGate loading={<LoadingScreen />}>
              <RefreshProvider>
                <CartProvider>
                  <AxeptioScriptLoader />
                  <RouterProvider router={router} />
                </CartProvider>
              </RefreshProvider>
            </StorePersistGate>
          </ErrorBoundary>
        </Provider>
      </App>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
