import React from "react";
import { Card, Button, Collapse, Empty } from "antd";
import { User } from "../../User/types/modelUser";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import { openModalAction } from "../../common/state/modal.slice";
import adminApi from "../services/admin.api";
import { useAppDispatch } from "../../common/hooks";
import UserForm from "./Forms/UserForm";

/**
 * Props for the `AdminListUserMobile` component.
 * @interface AdminListUserMobileProps
 */
interface AdminListUserMobileProps {
  /**
   * The list of user accounts to display.
   * @type {User[] | undefined}
   */
  accounts: User[] | undefined;

  /**
   * Callback function to refresh the list of users.
   * @type {Function}
   */
  fetchUsersAdmin: () => void;
}

/**
 * Mobile-friendly component to display and manage a list of users.
 * @component
 * @param {AdminListUserMobileProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const AdminListUserMobile: React.FC<AdminListUserMobileProps> = ({
  accounts,
  fetchUsersAdmin,
}) => {
  const dispatch = useAppDispatch();

  /**
   * Opens a modal to edit a user's details.
   * @param {User} user - The user to edit.
   */
  const openModalEditUser = (user: User) => {
    dispatch(
      openModalAction({
        component: (
          <UserForm user={user} formCb={() => fetchUsersAdmin()} />
        ),
        options: { title: "Modifier un utilisateur" },
      })
    );
  };

  /**
   * Opens a modal to confirm and delete a user.
   * @param {User} user - The user to delete.
   */
  const openModalDeleteUser = (user: User) => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm) {
                await adminApi.deleteUser(user._id ?? "");
                fetchUsersAdmin();
              }
            }}
            confirmIsDanger
            body="Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
          />
        ),
        options: { title: "Supprimer un utilisateur" },
      })
    );
  };

  /**
   * Defines the collapse items structure for the mobile user list.
   * @constant
   * @type {Array<Object>}
   */
  const collapseItems = [
    {
      key: "1",
      label: "Utilisateurs",
      children:
        accounts && accounts.length > 0 ? (
          <div
            className="grid grid-cols-1 gap-4"
            style={{ gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))" }}
          >
            {accounts.map((user: User) => (
              <Card
                key={user._id}
                title={`${user.firstName} ${user.lastName}`}
                extra={<span>{user.email}</span>}
                actions={[
                  <Button
                    key="edit"
                    onClick={() => openModalEditUser(user)}
                  >
                    Modifier
                  </Button>,
                  <Button
                    key="delete"
                    danger
                    onClick={() => openModalDeleteUser(user)}
                  >
                    Supprimer
                  </Button>,
                ]}
              >
                <p><strong>Téléphone:</strong> {user.mobile || "Non renseigné"}</p>
                <p><strong>Email:</strong> {user.email}</p>
              </Card>
            ))}
          </div>
        ) : (
          <Empty description="Aucun utilisateur trouvé" />
        ),
    },
  ];

  return <Collapse items={collapseItems} />;
};

export default AdminListUserMobile;
