import React, { useEffect } from 'react';

// Déclaration globale pour axeptioSettings
declare global {
  interface Window {
    axeptioSettings?: {
      clientId: string;
      cookiesVersion: string;
      googleConsentMode?: Record<string, any>;
      debug?: boolean;
      sdkLoaded?: boolean; // Ajout d'un indicateur
    };
  }
}

const AXEPTIO_SCRIPT_URL = "https://static.axept.io/sdk.js";

const AxeptioScriptLoader: React.FC = () => {
  useEffect(() => {
    // Empêcher le rechargement si le SDK est déjà initialisé
    if (window.axeptioSettings?.sdkLoaded) {
      console.info("Axeptio SDK is already initialized.");
      return;
    }

    // Initialiser les paramètres axeptioSettings
    window.axeptioSettings = {
      ...(window.axeptioSettings || {}),
      clientId: "66c887ea8d5ad420eee1917a",
      cookiesVersion: "jbconnect91-fr-EU",
      googleConsentMode: {
        default: {
          analytics_storage: "denied",
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          wait_for_update: 500,
        },
      },
      debug: true, // Activer le mode debug
      sdkLoaded: false, // Marquer comme non chargé initialement
    };


    const existingScript = document.querySelector(`script[src="${AXEPTIO_SCRIPT_URL}"]`);
    if (existingScript) {
      document.body.removeChild(existingScript);
    }

    // Vérifier si le script est déjà dans le DOM
    if (document.querySelector(`script[src="${AXEPTIO_SCRIPT_URL}"]`)) {
      console.warn("Axeptio script is already loaded in the DOM.");
      window.axeptioSettings.sdkLoaded = true;
      return;
    }

    // Charger dynamiquement le script
    const script = document.createElement("script");
    script.async = true;
    script.src = AXEPTIO_SCRIPT_URL;

    script.onload = () => {
      console.info("Axeptio script loaded successfully.");
      window.axeptioSettings!.sdkLoaded = true; // Marquer comme chargé
    };

    script.onerror = () => {
      console.error("Error loading Axeptio script.");
    };

    document.body.appendChild(script);

    // Nettoyage lors du démontage
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default AxeptioScriptLoader;
