import { Button, Empty, Result, Card, List, Typography, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ModalConfirm from "../../common/components/Modals/modal-confirm.component";
import { useAppDispatch } from "../../common/hooks";
import { ApiListResult } from "../../common/services/types";
import { openModalAction } from "../../common/state/modal.slice";
import { TimelineStatusEnum } from "./types/LogTypes";
import { Log } from "./types/modelLog";
import TypeTimelineItem from "./TypeTimelineItem";
import logApi from "./services/log.api";

const { Text, Title } = Typography;

const Logs = () => {
  const [logs, setLogs] = useState<ApiListResult<Log>>();
  const dispatch = useAppDispatch();

  const fetchLogs = async () => {
    try {
      const res = await logApi.findLogs({}, 1, 10);
      setLogs(res);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleDeleteAppointment = async (log: Log) => {
    try {
      await logApi.deleteLogAndAppointment(log._id!);
      fetchLogs();
    } catch (error) {
      console.error(error);
    }
  };

  const openModalDeleteAppointment = (log: Log) => {
    dispatch(
      openModalAction({
        component: (
          <ModalConfirm
            formCb={async (confirm) => {
              if (confirm) {
                await handleDeleteAppointment(log);
              }
            }}
            body={
              <div>
                <p>
                  Vous êtes sur le point de libérer le créneau du{" "}
                  {dayjs(log.appointmentId.date).format("dddd D MMMM YYYY")} à{" "}
                  {dayjs(log.appointmentId.date).format("HH:mm")}
                </p>
                <p>
                  Service : <strong>{log.serviceId.name}</strong>
                </p>
              </div>
            }
          />
        ),
        options: {
          title: "Voulez-vous libérer ce créneau ?",
        },
      })
    );
  };

  const groupLogsByDate = (logs: Log[]) => {
    return logs.reduce((acc, log) => {
      const date = dayjs(log.timestamp).format("D MMMM YYYY");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(log);
      return acc;
    }, {} as Record<string, Log[]>);
  };

  const groupedLogs = logs ? groupLogsByDate(logs.rows) : {};

  return (
    <div className="p-4">
      {logs && logs.rows && logs.rows.length > 0 ? (
        Object.entries(groupedLogs).map(([date, logsForDate]) => (
          <Card
            key={date}
            title={<Title level={4}>{date}</Title>}
            bordered={true}
            className="mb-4"
          >
            <List
              dataSource={logsForDate}
              renderItem={(log) => (
                <List.Item
                  className="flex flex-col md:flex-row md:items-center"
                  actions={[
                    log.typeAction === TimelineStatusEnum.delete && (
                      <Button
                        type="primary"
                        size="small"
                        disabled={log.appointmentId.date < new Date()}
                        onClick={() => openModalDeleteAppointment(log)}
                      >
                        Libérer le créneau
                      </Button>
                    ),
                  ]}
                >
                  <Space direction="vertical" className="w-full">
                    <TypeTimelineItem typeAction={log.typeAction} />
                    <div>
                      <Text strong>{log.descriptionAction}</Text>
                      <br />
                      <Text type="secondary">{log.serviceId.name}</Text>
                    </div>
                  </Space>
                </List.Item>
              )}
            />
          </Card>
        ))
      ) : (
        <Result
          icon={<Empty />}
          title="Aucun logs à afficher."
          className="mt-20"
        />
      )}
    </div>
  );
};

export default Logs;
